import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/cavalologo.svg";
import up from "../assets/up.svg";

const Wrapper = styled.div.attrs({
  className: "contactus-strip",
})`
  &.contactus-strip {
    background-color: #4e5268;
  }

  .contactus-strip-inner {
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .contactus-strip-inner h5 {
    font-size: 26px;
    font-family: "Mulish";
    font-weight: 800;
    color: #ffffff;
    margin: 3rem 0;
  }

  .contactus-strip-inner p {
    font-size: 2.4rem;
    font-family: "Mulish";
    color: #ffffff;
    letter-spacing: 1.3;
    margin-left: 1rem;
  }
  .contactus-strip-inner p span {
    background: #f6af33;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .browse {
    display: none;
  }

  @media (max-width: 1500px) {
    .contactus-strip-inner h5 {
      font-size: 2rem;

      margin: 2.5rem 0;
    }

    .contactus-strip-inner p {
      font-size: 1.7rem;

      letter-spacing: 1.2;
    }
  }

  @media (max-width: 1200px) {
    .contactus-strip-inner h5 {
      font-size: 1.5rem;

      margin: 2rem 0;
    }

    .contactus-strip-inner p {
      font-size: 1.3rem;

      letter-spacing: 1.1;
    }
  }

  @media (max-width: 1000px) {
    .browse {
      display: flex;
      background: #1e2130;
      width: 100vw;
      flex-direction: column;
      margin-top: 1rem;
      padding-top: 2.6rem;
      padding-bottom: 2.8rem;

      border-bottom: 1px solid #3f4356;
    }
    .img {
      margin: 1rem auto;
    }
    .button {
      display: flex;
      width: 80vw;
      justify-content: space-around;
    }
    .button button {
      padding: 2rem 2.5rem;
      border-radius: 0.7rem;
      background: #f4ba00;
      font-weight: 700;
      font-size: 14px;
      border: none;
      outline: none;
    }
    .bro {
      color: #1e2130 !important;
    }
    .bro:hover,
    .bro:focus {
      background: #ffd54b;
      border: 1px solid white;
    }
    .get {
      background: #3b415f !important;
      color: white !important ;
      display: flex;
      justify-content: space-between;
    }
    .get img {
      margin: auto 1rem;
      margin-right: 0;
    }
    .get:hover,
    .get:focus {
      /* background: #ffd54b; */
      border: 1px solid white;
    }
    .contact {
      display: none;
    }
    .contactus-strip {
      background: #1e2130;
    }
  }
  @media (max-width: 1000px) {
    .button {
      width: 90vw;
    }
  }
`;

const ContactStrip = ({show=true}) => {
  const navigate = useNavigate();
  return (
    <Wrapper className="contactus-strip">
      <div className="contactus-strip-inner contact">
        <h5 className="contactus-big-text">Need help with buying? </h5>
        <p className="">
          Mail us your queries at <span>info@cavalo.in</span> or call us at
          <span> 7021411346</span> and we'll be happy to assist!
        </p>
      </div>
      <div className="contactus-strip-inner browse">
        <img className="img" src={logo} alt="" />
        {show && <div className="button">
          <button
            className="bro"
            onClick={() => {
              navigate("/second-hand-truck-for-sale");
            }}
          >
            Browse trucks
          </button>
          <button
            className="get"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            Get to Top
            <img src={up} alt="" />
          </button>
        </div>}
      </div>
    </Wrapper>
  );
};

export default ContactStrip;
