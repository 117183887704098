import { Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RateCard } from "../pages/Finance3";

export default function EmiCalculator({ manualAmount }) {
    const [rate, setRate] = useState(10);
    const [months, setMonths] = useState("");
    const [amount, setAmount] = useState(500000);
    const [downPayment, setDownPayment] = useState(0)
    const [years, setYears] = useState(5)

    useEffect(() => {
        if (typeof manualAmount === "number") {
            setAmount(manualAmount)
        }
    }, [manualAmount])

    const handleClick = () => {
        let lamount = amount - downPayment
        let lrate = rate;
        setMonths(
            <>
                <RateCard amount={lamount} rate={lrate} installments={years * 12} hideNoCost />
            </>
        )
    };

    return (
        <>
            <div className="new-container">
                <div className="inner-flex" style={{ flexDirection: 'column' }}>
                    {
                        manualAmount &&
                        <div className="slider-flex">
                            <h1 style={{fontWeight:'500'}}>
                                Amount:{" "}{amount}
                            </h1>
                            <Slider
                                aria-label="Temperature"
                                defaultValue={amount}
                                // getAriaValueText={valuetext}
                                sx={{
                                    color: "#f4ba00",
                                }}
                                min={10000}
                                max={10000000}
                                step={50000}
                                valueLabelDisplay="auto"
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                    }
                    <div className="slider-flex">
                        <h1 style={{fontWeight:'500'}}>Rate: {rate}%</h1>
                        <Slider
                            aria-label="Temperature"
                            defaultValue={rate}
                            // getAriaValueText={valuetext}
                            sx={{
                                color: "#f4ba00",
                            }}
                            min={5}
                            max={20}
                            step={0.5}
                            valueLabelDisplay="auto"
                            onChange={(e) => setRate(e.target.value)}
                        />
                    </div>
                    <div className="slider-flex">
                        <h1 style={{fontWeight:'500'}}>Downpayment: {downPayment}</h1>
                        <Slider
                            aria-label="Temperature"
                            defaultValue={downPayment}
                            // getAriaValueText={valuetext}
                            sx={{
                                color: "#f4ba00",
                            }}
                            min={0}
                            max={amount}
                            step={1000}
                            valueLabelDisplay="auto"
                            onChange={(e) => setDownPayment(e.target.value)}
                        />
                    </div>
                    <div className="slider-flex" style={{width:'85%',overflowX:'scroll'}}>
                        <h1 style={{fontWeight:'500'}}>Years: {years}</h1>
                        <div style={{ gap: 20, display: 'flex', marginTop: 10, }}>

                            {
                                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(el => <>
                                    <div
                                        style={{
                                            borderRadius: 20,

                                            paddingLeft: 20,
                                            paddingRight: 20,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            backgroundColor: '#f4ba00',
                                            fontSize: 14,
                                            cursor: 'pointer',
                                            opacity: years === el ? 1 : 0.4
                                        }}
                                        onClick={() => setYears(el)}
                                    >
                                        {el}
                                    </div>

                                </>)
                            }
                        </div>

                    </div>
                </div>




                <div className="inner-flex-2" style={{marginTop:'30px'}}>
                    <button onClick={handleClick} style={{background:'#f4ba00',border:'1px solid #f4ba00',padding:'10px 20px',borderRadius:'4px',fontSize:'16px',color:'#fff'}}>Calculate</button>
                </div>
                <div>
                    {months}
                </div>
            </div>
        </>
    );
};

