import React, { useEffect } from "react";
import Wrapper from "../wrappers/Common.js";

import RightArrow from "../assets/breadCrumRightArrow.svg";
import StepIncompleted from "../assets/StepCompleted.svg";
import StepCompleted from "../assets/StepIncompleted.svg";
import StepCurrent from "../assets/StepCurrent.svg";
import HomeIcon from "../assets/homeIcon.svg";
import Home2Icon from "../assets/Home2Icon.svg";
import CashIcon from "../assets/CashIcon.svg";
import LocationIcon from "../assets/Location2Icon.svg";
import FinanceIcon from "../assets/FinanceIcon.svg";
import UsersIcon from "../assets/UsersIcon.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Cross from "../assets/CrossIcon.svg";
import Box from "@mui/material/Box";
import { Stack, Skeleton, CircularProgress } from "@mui/material";

import ExploreTruckImg from "../assets/exploreTruckImg.png";

import styled from "styled-components";
import { useState } from "react";
import {
  Navbar,
  Services,
  HappyCustomers,
  Faq,
  ContactStrip,
  Footer,
} from "../components/index";
import { checked } from "../features/landing/landingSlice";
import { useNavigate, createSearchParams } from "react-router-dom";
import { get, post } from "../utils/functions.js";
import { toast } from "react-hot-toast";
import axios from "axios";
import Speedometer from "../components/Speedometer.jsx";
import { useMediaQuery } from "../utils/useMediaQuery.js";
import { root_url } from "../utils/url.constant.js";
import "./productDetail.css";

const FinanceWrapper = styled.div`
  .breadcrumb-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3rem auto;
    width: 90%;
  }

  .breadcrumb-container img,
  span {
    margin-right: 1rem;
  }

  .breadcrumb-container img:first-child {
    width: 2rem;
  }

  .breadcrumb-container span {
    font-size: 1.3rem;
    color: #667085;
  }

  .current-page-breadcrumb-bg {
    background-color: #fdebb0;
    color: #1e2130;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
  }

  .book-now-section {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1.4fr 1fr;
    gap: 9.5rem;
    box-sizing: border-box;
    /* overflow: scroll; */
  }
  .progress-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    max-width: 80%;
    margin: 0 auto;
    margin-bottom: 3rem;
    /* margin: 0 2rem; */
  }

  .progress {
    position: absolute;
    border: 2px dashed #e0e0e0;

    top: 50%;
    left: 0;
    transform: translateY(-50%);
    /* height: 2px; */
    width: 98%;
    z-index: -1;

    box-sizing: border-box;
  }

  .progress::before {
    content: "";
    position: absolute;
    border: 2px solid #f8b301;
    /* background-color: #f8b301; */
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    /* height: 2px; */
    width: 100%;
    z-index: -1;
  }
  .name-below-1 {
    position: relative;
  }
  .name-below-1 p {
    position: absolute;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #616161;
    top: 100%;
    width: 12rem;
    left: -125%;
    right: 0%;

    /* left: -9rem; */
    /* right: 100%; */
  }

  .circle img {
  }

  .active {
  }

  .white {
    background-color: white;
  }
  .book-now-heading-3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  .book-now-heading-3 h3 {
    font-size: 3.5rem;
    margin-bottom: 0;
  }

  .book-now-heading-3 p {
    font-size: 1.5rem;
    margin: 1rem 0;
    color: #616161;
  }
  .status {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .status h1 {
    color: #616161;
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
  }
  .status span {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 34px;
    color: var(--basic-off-navy-blue, #1e2130);
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 0;
  }

  .approvedContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .one {
  }
  .one h2 {
    color: #1e2130;
    font-size: 24px;
    font-weight: 700;
    margin-top: 0;
  }
  .one p {
    color: #616161;
    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
  }
  .two {
    border-radius: 36px;
    background: #f7f7f7;
    display: flex;
    width: 432px;
    padding: 12px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;
    text-shadow: 0px 0px 12px 0px rgba(248, 179, 1, 0.15);
    font-family: Mulish;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .three {
    color: #616161;
    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
  }
  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    width: 70%;
  }
  .payment-method-container {
    padding: 3rem 0;
    width: 100%;
  }
  .vehicle-details-container {
    max-width: 473px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 13px;
    gap: 13px;
    align-self: flex-end;
    margin-left: auto;
    width: 100%;
  }

  .vehicle-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 28px 32px;
    width: 100%;
    /* height: 334px; */
    border-radius: 18px;
    border: 1.5px solid #e0e0e0;
    background: #fff;
    /* margin-bottom: 2rem; */
    gap: 10px;
    box-sizing: border-box;
  }
  .truck-heading {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 48px;
    align-items: center;
    gap: 0;
    height: 108px;
    justify-content: start;
  }
  .truck-heading img {
    width: 92px;
    height: 92px;
    border-radius: 8px;
    background: url(<path-to-image>), lightgray 50% / cover no-repeat, #fff;
    box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.04);
  }
  .heading {
    height: 108px;
    display: grid;
    grid-template-rows: 1.2fr 0.9fr 1.4fr;
    grid-gap: 5px;
    overflow: visible;
  }

  .heading h3 {
    color: var(--basic-black, #101010);
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 0;
    height: fit-content;
  }
  .span-heading {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 8px;
    margin-top: 10px;
  }
  .span-heading span {
    display: flex;
    padding: 7px 14px;
    align-items: flex-start;
    border-radius: 5px;
    opacity: 0.800000011920929;
    background: var(--basic-off-navy-blue, #1e2130);
    /* backdrop-filter: blur(9.502872467041016px); */
    color: #fff;
    margin: 0;
  }
  .sub-details span {
    color: #666666;
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    margin: 0;
  }
  .sub-details {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 17px;
    width: 92%;
  }

  .payment-method {
    padding: 2rem;
    border: 1px solid #e0e0e0;
    border-radius: 0.8rem;
    position: relative;
    display: flex;
    margin-bottom: 2rem;
    /* width: 100%;  */
  }

  .payment-method input {
    padding: 0 2rem;

    height: 6rem;
  }
  .payment-method h4 {
    font-size: 2.2rem;
    margin: 1rem 0;
    display: flex;
    align-items: center;
  }

  .payment-method p {
    font-size: 1.5rem;
    color: #797979;
    letter-spacing: 1.5;
    width: 70%;
    margin: 0;
  }

  .payment-method img {
    margin: 1rem;
  }

  .select-method {
    display: flex;
    padding: 17px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--basic-cavyellow, #f8b301);
    color: var(--basic-off-navy-blue, #1e2130);
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;
    margin-top: 3.6rem;
  }

  .select-method:disabled {
    opacity: 0.5;
  }
  /* truck detail */
  .item-wrapper {
    margin: 2rem 2rem;
  }
  .explore-truck-item {
    width: 100%;
    /* border: 1px solid red; */
    box-sizing: border-box;
    position: relative;

    border-radius: 2rem;
  }

  .explore-truck-img img {
    width: 100%;
  }

  .truck-type {
    margin: 2rem 0;
  }

  .truck-type span {
    padding: 0.8rem 2rem;
    margin-right: 1rem;
    letter-spacing: 1.1;
    color: #ffffff;
    font-size: 1.5rem;
    border-radius: 0.8rem;
    background-color: #1e2130;
    opacity: 0.8;
  }
  .explore-truck-description {
    box-sizing: border-box;
    /* text-align: center; */
  }

  .explore-truck-description h3,
  .emi-calculator h3 {
    color: #000000;
    font-size: 250%;
    margin: 0 0 1rem 0;
  }

  .explore-truck-details {
    font-size: 130%;
    color: #1e2130;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    margin-top: 15px;
  }
  .explore-truck-details span {
    margin: 0 0.5rem;
  }
  .container {
    display: flex;
    /* width: 695px; */
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    overflow: hidden;
  }

  .first {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  .second {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
  }
  .third {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .explore-truck-details p {
    margin: 1.5rem;
    margin-top: 0;
    margin-left: 0;

    display: flex;
    align-items: center;
  }

  .explore-truck-details img {
    width: 1.5rem;
    margin-right: 1rem;
  }
  .explore-truck-price {
    display: flex;
    align-items: flex-end;
    justify-content: start;
    /* margin-bottom: 1rem;
    padding-top: 2rem; */
  }
  .explore-truck-price span:first-child {
    color: var(--basic-off-navy-blue, #1e2130);
    font-family: Mulish;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.56px;
  }

  .explore-truck-price span:last-child {
    font-size: 1.7rem;
    color: #7d808e;
    text-decoration-line: line-through;
  }

  .inclusive {
    color: #7d808e;
    font-size: 150%;
    text-align: left;
    margin: 0 1rem;
  }
  .vehicle-detail-image {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .vehicle-detail-image img {
    width: 70%;
  }

  .emi-calculator {
    margin-bottom: 1rem;
    border: 1px solid #e0e0e0;
    padding: 2rem;
    border-radius: 18px;
    border: 1.5px solid #e0e0e0;
    width: 100%;
    box-sizing: border-box;
  }

  .emi-calculator p {
    color: #6a6d7e;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .emi-calculator button {
    background-color: black;
    border-radius: 0.8rem;
    color: #fff;
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 17px 24px;
    border-radius: 8px;
    background: #1e2130;
    display: flex;
    padding: 17px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
  .reqId {
    border-radius: 18px;
    border: 1.5px solid #e0e0e0;
    background: #fff;
    padding: 20px 29px;
    color: #3e3b3b;
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    width: 100%;

    box-sizing: border-box;
  }
  .reqId .bold {
    color: #1e2130;
    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
  }
  .cibil {
    display: flex;
    /* width: 473px; */
    padding: 32px 28px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-radius: 18px;
    width: 100%;
    box-sizing: border-box;
    border: 1.5px solid #e0e0e0;
  }
  .cibil h1 {
    color: var(--basic-off-navy-blue, #1e2130);
    font-family: Mulish;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 0;
  }
  .cibil p {
    color: #6a6d7e;
    text-align: center;
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
  .message {
    width: 100%;
    height:fit-content;
    flex-shrink: 0;
    border-radius: 18px;
    border: 1.5px solid #f4ba00;
    background: #fff;
    margin: 2rem auto;
  }

  .message h1 {
    width: 417px;
    height: 38px;
    flex-shrink: 0;
    color: #f8b301;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 147%; /* 35.28px */
    letter-spacing: 0.48px;
    margin: 1rem auto;
    border-bottom: 1px solid #fee182;
    margin-bottom: 0.5rem;
    text-align: center;
    max-width: 90%;
  }

  .message p {
    width: 593px;
    flex-shrink: 0;
    color: #24252f;
    text-align: center;
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
    margin: 0rem auto;
    max-width: 90%;
  }

  #overlay {
    background-color: black;
    opacity: 0.5;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  #popup {
    padding: 32px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 3rem;
    z-index: 20;
    box-sizing: border-box;
    max-width: 132rem;
    width: 841px;
    height: 677px;
    overflow: scroll;
    max-height: 90vh;
    background:
		/* Shadow covers */ linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */
        radial-gradient(
          50% 0,
          farthest-side,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
      radial-gradient(
          50% 100%,
          farthest-side,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        0 100%;
    background: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), #ffffff 66%) 0 100%,
      radial-gradient(
        farthest-side at 50% 0,
        rgba(34, 34, 34, 0.5),
        rgba(0, 0, 0, 0)
      ),
      radial-gradient(
          farthest-side at 50% 100%,
          rgba(34, 34, 34, 0.5),
          rgba(0, 0, 0, 0)
        )
        0 100%;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-attachment: local, local, scroll, scroll;
    background-size: 100% 87px, 100% 87px, 100% 29px, 100% 29px;
  }
  #popup::-webkit-scrollbar {
    width: 0;
  }

  .popup-hide {
    display: none;
  }
  .popup h3 {
    color: #6a6d7e;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
  .terms {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
  }
  .popup h4 {
    margin: 0;
    color: #7d808e;
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;
    margin-top: 42px;
  }
  .carddContainer {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    padding: 24px 0px;
  }
  .details h4 {
    color: #c27d15;
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }
  .details {
    display: flex;
    width: 416px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex-shrink: 0;
  }
  .details h1 {
    color: var(--basic-off-navy-blue, #1e2130);
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
  .details p {
    color: #818d9d;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
  .price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  .price h1 {
    color: var(--basic-off-navy-blue, #1e2130);
    font-family: Mulish;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.56px;
    margin: 0;
  }
  .price p {
    color: var(--gray, #818d9d);
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }

  .popup-show {
    display: flex;
    width: 100%;
  }

  .btn-close {
    background: transparent;
    border: none;
  }
  .popup-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .popup-container h1 {
    margin: 1rem 0;
    width: fit-content;
  }
  .heading-1 {
    display: flex;
    justify-content: space-between;
    /* min-width: 35vw;  */
  }
  .grid-fields {
    display: flex;
    width: 777px;
    align-items: flex-start;
    gap: 18px;
    margin-top: 14px;
  }
  .grid-fields button {
    display: flex;
    padding: 14px 18px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    color: var(--basic-white, #fff);
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    border-radius: 8px;
    background: #1e2130;
    margin: auto 0; /* 24px */
  }

  .select-container {
    border-radius: 8px;
    background: var(--field-off-white, #fafafa);
    box-sizing: border-box;
    display: block;
    cursor: pointer;
    height: 54px;
    margin: 9px 0;
    display: flex;
    padding: 14px 6px 14px 18px;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
  }
  .select-container select {
    border: none;
    width: 100%;
    height: 100%;
    background-color: transparent;
    /* box-sizing: border-box; */
    display: block;
    outline: none;
    color: #757575;
    font-weight: 400;
    font-size: 16px;
  }
  .select-container input {
    border: none;
    width: 100%;
    height: 100%;
    background-color: inherit;
    /* box-sizing: border-box; */
    display: block;
    outline: none;
    color: #757575;
    font-weight: 400;
    font-size: 16px;
  }
  .select-container span {
    width: 17.98px;
    height: 17px;
  }
  .steps-and-chart {
  }
  .warn {
    color: #616161;
  }
  .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
    border-color: #e0e0e0 !important;
    /* margin: 9px 0 !important; */

    font-family: "Mulish" !important;
    font-weight: 400 !important;
    font-size: 14px !important;

    /* identical to box height */

    color: #757575 !important;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #979797 !important;
  }
  .Mui-focused {
    color: #616161 !important;
    border-color: #979797 !important;
  }
  .MuiSelect-select,
  .MuiInputLabel-root {
    font-family: "Mulish" !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    color: #757575 !important;

    /* padding-left: 18px; */
  }
  .MuiSelect-select {
    color: #000000 !important;
  }
  .MuiInputLabel-shrink {
    display: none;
  }
  .MuiFormControl-root {
  }
  .MuiButtonBase-root,
  .MuiMenuItem-root {
    font-size: 16px !important;
  }
  .mobile-vehicle {
    display: none;
  }
  .reqId-1 {
    display: none;
  }

  @media (min-width: 1600px) {
    .book-now-section {
      grid-template-columns: 1.8fr 1fr;
    }
    .select-method {
      width: 80%;
      margin: 3.6rem auto;
    }
  }
  /*  */
  @media (max-width: 1300px) {
    .book-now-section {
      width: 95vw;
      grid-template-columns: 2fr 1fr;
      gap: 1rem;
      overflow: scroll;
    }

    .payment-method-container {
      width: 100%;
    }
    .progress-container p {
      font-size: 11px !important;
    }
  }
  @media (max-width: 1100px) {
    .book-now-section {
      display: flex;
      flex-direction: column;
    }
    .vehicle-details {
      display: none;
    }
    .vehicle-details-container {
      margin: 0 auto;
    }
    .two {
      width: 80%;
    }
    .image {
      display: none;
    }
    .approvedContainer {
      display: flex;
    }
    .mobile-vehicle {
      display: flex;
      justify-content: space-between;
    }
    .vehicle-container {
      display: inline-flex;
      padding: 14px 18px 16px 18px;
      align-items: flex-start;
      gap: 8rem;
      background: var(--base-white, #fff);
      min-width: 60%;
      justify-content: flex-start;
    }
    .vehicle-container img {
      display: flex;
      width: 168px;
      height: 168px;
      padding: 4px;
      flex-direction: column;
      align-items: flex-end;
      gap: 8px;
      border-radius: 10px;
      /* background: var(
        --img-bottom-fade,
        linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%),
        url(<path-to-image>),
        lightgray 50% / cover no-repeat
      ); */
    }
    .mobile-heading {
      display: flex;
      /* align-items: center; */
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
    }
    .mobile-heading h3 {
      color: var(--base-navy-blue, #1e2130);
      font-family: Mulish;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.26px;
      margin: 0;
    }
    .mobile-heading p {
      color: var(--text-sub-text-1-gray, #4f5261);
      font-family: Mulish;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.26px;
      margin: 0;
    }
    .price {
    }
    .price h1 {
      color: var(--base-navy-blue, #1e2130);
      font-family: Mulish;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.32px;
      align-self: stretch;
      margin: 0;
    }
    .price p {
      color: var(--text-sub-text-2-gray, #8c8c8c);
      font-family: Mulish;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.2px;
      margin: 0;
    }
    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .image-container svg {
      width: 108px;
      height: 108px;
      flex-shrink: 0;
    }
    .message {
      width: 98%;
    }
    .reqId-1 {
      display: block;
      border-top: 1.5px solid #e0e0e0;
      border-bottom: 1.5px solid #e0e0e0;
      background: #fff;
      padding: 10px 28px;
      ccolor: #3e3b3b;
      font-family: Mulish;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.22px;
      width: 95vw;
      position: relative;
      left: -2vw;
      box-sizing: border-box;
      text-align: center;
    }
    .reqId-1 .bold {
      color: #1e2130;
      font-family: Mulish;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.26px;
    }
    .reqId {
      display: none;
    }
  }
  @media (max-width: 841px) {
    #popup {
      width: 100vw;
      height: 100vh;
      overflow: scroll;
    }

    .popup h3 {
      color: #6a6d7e;
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
    .popup h4 {
      margin: 0;
      color: #7d808e;
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.28px;
      margin-top: 42px;
    }
    .carddContainer {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e0e0e0;
    }
    .details {
      display: flex;
      width: 416px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      flex-shrink: 0;
    }
    .details h1 {
      color: var(--basic-off-navy-blue, #1e2130);
      font-family: Mulish;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .details p {
      color: #818d9d;
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .price {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }
    .price h1 {
      color: var(--basic-off-navy-blue, #1e2130);
      font-family: Mulish;
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 0.56px;
    }
    .price p {
      color: var(--gray, #818d9d);
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .popup-show {
      display: flex;
      width: 100%;
    }
  }
  .bold {
    color: #1e2130;
    font-family: Mulish;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.26px;
  }

  @media (max-width: 800px) {
    .book-now-section {
      width: 95%;
      grid-template-columns: 1fr;
      grid-template-rows: 1.2fr 1fr;
      gap: 2rem;
    }

    .payment-method-container {
      width: 100%;
    }
  }

  @media (max-width: 642px) {
    .book-now-section {
      grid-template-rows: 0.95fr 1fr;
    }
    .container {
      width: 91vw;
      margin: 0 auto;
      margin-top: 22px;
    }
    .reqId-1 {
      margin-top: 6rem;
    }
    .mobile-vehicle {
      justify-content: space-between;
      margin-top: 1rem;
    }
    .mobile-vehicle {
      display: flex;
      justify-content: space-between;
      min-width: auto;
      width: auto;
    }
    .vehicle-container {
      display: inline-flex;
      padding: 14px 18px 16px 18px;
      align-items: flex-start;
      gap: 12px;
      background: var(--base-white, #fff);
    }
    .vehicle-container img {
      display: flex;
      width: 84px;
      height: 84px;
      padding: 4px;
      flex-direction: column;
      align-items: flex-end;
      gap: 8px;
      border-radius: 10px;
      /* background: var(
        --img-bottom-fade,
        linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%),
        url(<path-to-image>),
        lightgray 50% / cover no-repeat
      ); */
    }
    .mobile-heading {
      gap: 0.25rem;
    }
    .mobile-heading h3 {
      color: var(--base-navy-blue, #1e2130);
      font-family: Mulish;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.26px;
      margin: 0;
    }
    .mobile-heading p {
      color: var(--text-sub-text-2-gray, #8c8c8c);
      font-family: Mulish;
      font-size: 13px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.2px;
      margin-top: 3px;
    }
    .price {
    }
    .price h1 {
      color: var(--base-navy-blue, #1e2130);
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.32px;
      align-self: stretch;
      margin: 0;
    }
    .price p {
      color: var(--text-sub-text-2-gray, #8c8c8c);
      font-family: Mulish;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.2px;
      margin: 0;
    }
    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .image-container svg {
      width: 54px;
      height: 54px;
      flex-shrink: 0;
    }
  }

  @media (max-width: 500px) {
    .status span {
      display: flex;
      padding: 6px 14px !important;
      justify-content: center;
      align-items: center;
    }
    .breadcrumb-container {
      display: none;
    }
    .cibil,
    .emi-calculator {
      border-radius: 13.091px;
      border: 1.091px solid #e0e0e0;
    }
    .message {
      width: 100%;
      flex-shrink: 0;
      border-radius: 18px;
      border: 1.5px solid #f4ba00;
      background: #fff;
      margin: 0;
      margin-top: 35px;
      margin-bottom: 4px;
      padding: 19px 23px;
      box-sizing: border-box;
      height: fit-content;
    }
    .message h1 {
      width: 417px;
      height: 38px;
      flex-shrink: 0;
      color: #f8b301;
      font-family: Mulish;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 147%; /* 29.4px */
      letter-spacing: 0.4px;
      margin: 0rem auto;
      border-bottom: 1px solid #fee182;
      margin-bottom: 1.2rem;
      text-align: center;
      max-width: 90%;
    }

    .message p {
      width: 593px;
      flex-shrink: 0;
      color: #24252f;
      text-align: center;
      color: #24252f;
      text-align: center;
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.32px;
      letter-spacing: 0.4px;
      margin: 0rem auto;
      max-width: 90%;
    }
    .speedometer {
    }

    .one h2 {
      color: #1e2130;
      font-family: Mulish;
      font-size: 20px;
      font-weight: 700;
      margin-top: 0;
    }
    .one p {
      color: #616161;
      letter-spacing: 0.4px;
      font-family: Mulish;
      font-size: 16px;
      width: 90vw;
      margin: 0;
    }
    .two {
      width: 90vw;
      box-sizing: border-box;
    }
    .approvedContainer {
      flex-direction: column-reverse;
    }
    .image {
      position: absolute;
      align-self: flex-end;
      width: 54px;
      height: 54px;
      top: 0;
    }
    .image svg {
      width: 54px;
      height: 54px;
    }
    .payment-method-container {
      padding: 0;
      position: relative;
    }
    .pending {
      margin-right: 0;
    }
    .three {
      color: #616161;
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.32px;
    }
    .steps-and-chart {
      height: fit-content;
    }
    .book-now-section {
      width: 100vw;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      /* gap: 5rem; */
      padding: 26px 18px;
      box-sizing: border-box;
      gap: 24px;
    }
    .cibil {
      width: 100%;
      box-sizing: border-box;
      padding: 0;
      gap: 5px;
      overflow: hidden;
      padding: 22.934px 20.068px;
      /* position: absolute; */
    }
    .cibil span {
      width: 90vw;
      /* height: 180px; */
    }
    .cibil h1 {
      color: var(--basic-off-navy-blue, #1e2130);
      font-family: Mulish;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin-bottom: 22.93px;
    }
    .cibil p {
      color: #6a6d7e;
      text-align: center;
      font-family: Mulish;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .speedometer {
      /* max-width: 80vw; */
    }
    .status h1 {
      display: none;
    }
    .book-now-heading-3 h3 {
      color: var(--basic-off-navy-blue, #1e2130);
      font-family: Mulish;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 24px */
      letter-spacing: -0.1px;
    }
    .book-now-heading-3 p {
      color: var(--text-sub-text-1-gray, #4f5261);
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.28px;
      margin: 0;
    }
    .content {
      margin-top: 26px;
      display: flex;
      width: 90vw;
      flex-direction: column;
      align-items: flex-start;
      gap: 21.519px;
    }
    .progress-container {
      width: 337px;
      margin: 0 auto;
    }
    .steps-and-chart {
      width: calc(100vw - 36px);
    }

    .payment-method {
      display: flex;
      /* width: 339px; */
      /* height: 97px; */
      padding: 17.335px 17.335px 20.224px 13.001px;
      align-items: flex-start;
      gap: 11.557px;
    }
    .payment-method h4 {
      margin: 0;
      font-family: Mulish;
      font-size: 15.89px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .payment-method p {
      margin: 0;
      color: #797979;
      font-family: Mulish;
      font-size: 10.112px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.202px;
      margin-right: 0;
      width: 100%;
    }
    .vehicle-details-container {
      width: calc(100vw - 36px);
    }
    .vehicle-details {
      padding: 0;
      border: none;
      width: calc(100vw - 36px);
    }
    .grid-fields {
      display: flex;
      align-items: flex-start;
      gap: 18px;
      margin-top: 14px;
      flex-direction: column;
      width: 100%;
    }
    .grid-fields .select-container,
    .grid-fields button {
      width: 100%;
    }

    .heading {
      width: 250px;
    }
    .emi-calculator h3 {
      font-family: Mulish;
      font-size: 20.364px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }
    .emi-calculator p {
      color: #6a6d7e;
      font-family: Mulish;
      font-size: 11.636px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .carddContainer {
      display: flex;
      padding: 14px 0px 24px 0px;
      align-items: center;
      gap: 18px;
      align-self: stretch;
    }
    .details h4 {
      color: #c27d15;
      font-family: Mulish;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0;
    }
    .details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      flex: 1 0 0;
    }
    .details h1 {
      color: var(--basic-off-navy-blue, #1e2130);
      font-family: Mulish;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
    }
    .details p {
      color: #818d9d;
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
    .price {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }
    .price h1 {
      color: var(--basic-off-navy-blue, #1e2130);
      font-family: Mulish;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 0.56px;
      margin: 0;
    }
    .price p {
      color: var(--gray, #818d9d);
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
    #popup {
      max-height: fit-content;
    }

    .popup-show {
      display: flex;
      width: 100%;
    }
  }

  @media (max-width: 400px) {
    .speedometer {
      transform: scale(0.85);
    }
  }
`;

const Finance = () => {
  const [active, setActive] = useState(1);
  const [data, setData] = useState();
  const [select, setSelect] = useState(0);
  const [searchParams, setsearchParams] = useState();
  const [first, setfirst] = useState(null);
  const [visibility, setVisibility] = useState("hide");
  const [rate, setRate] = useState("");
  const [months, setMonths] = useState("");
  const [emi, setEmi] = useState();
  const [amount, setAmount] = useState("");
  const [size, setsize] = useState([355, 197]);

  const onPayFull = () => {
    setSelect(1);
  };
  const onLoan = () => {
    setSelect(2);
  };

  const navigate = useNavigate();

  const getParams = () => {
    const foo = new URL(window.location.href);
    const searchParamss = Object.fromEntries(
      Array(...foo.searchParams.entries())
    );
    return searchParamss;
  };

  //data);

  useEffect(() => {
    first?.finance.listed_truck_id &&
      (async () => {
        try {
          const resp = await get(
            `/buy/view/${first?.finance.listed_truck_id}`,
            {},
            {}
          );
          if (resp.status_code === 200) {
            setData(resp.data);
            // setsearchParams(searchParamss);
          } else {
            toast.error(resp.status_message);
          }
        } catch (error) { }
      })();
  }, [first]);
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      localStorage.clear();

      toast.error("Login to continue...");
      navigate("/");
    }
    window.scrollTo(0, 0);

    const circles = document.querySelectorAll(".circle");
    circles.forEach((circle, idx) => {
      circle.addEventListener("click", (e) => {
        // //e.target);
      });
    });
    const token = localStorage.getItem("token");
    const searchParamss = getParams();
    searchParamss.process_id &&
      (async () => {
        try {
          const resp = await get(
            `/finance/${searchParamss?.process_id}`,
            {},
            {
              Authorization: `Bearer ${token}`,
            }
          );

          if (resp.status_code === 200) {
            setfirst(resp.data);
            setsearchParams(searchParamss);
          } else {
            toast.error(resp.status_message);
          }
        } catch (error) { }
      })();
  }, []);

  // const handleNavigate = () => {

  const openPopup = () => {
    setVisibility("show");
  };

  const closePopup = () => {
    setVisibility("hide");
  };
  const arr = [3, 6, 9, 12, 24, 36, 48, 60, 72, 84];

  const handleClick = () => {
    setMonths(
      arr.map((month) => {
        return <RateCard amount={amount} rate={rate} installments={month} />;
      })
    );
  };
  const handleProceed = async () => {
    const token = localStorage.getItem("token");
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${root_url}/finance/user/${first.finance.process_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: {
        is_user_proceed: "true",
      },
    };

    const resp = await axios.request(config);
    if (resp.data.status_code === 200) {
      toast.success(resp.data.status_message);
      navigate("/");
    } else {
      toast.error(resp?.data.status_message);
    }
  };

  const location = JSON.parse(localStorage.getItem("rto"));
  const handleContact = () => {
    const obj =
      "Cavalo" +
      "%0A" +
      "Need Help Regarding:" +
      "%0A" +
      "Finance Request I'D:" +
      "%20" +
      first.finance.process_id +
      "%0A" +
      "Status:" +
      "%20" +
      first?.finance.status.charAt(0).toUpperCase() +
      first?.finance.status.slice(1);

    const url = "https://wa.me/917021411346/?" + `text=${obj}&app_absent=0`;
    // //url)
    window.open(url);
  };

  const matches = useMediaQuery("(min-width: 768px)");
  useEffect(() => {
    // console.log(matches);
    if (matches) {
      setsize([355, 210]);
    } else {
      setsize([300, 197]);
    }
  }, [matches]);

  return (
    <Wrapper>
      <Navbar search={true} />
      {data ? (
        <FinanceWrapper>
          <div className="breadcrumb-container">
            <img src={HomeIcon} alt="home icon" />
            <img src={RightArrow} alt="home icon" />
            <span>
              Trucks in {location?.district.district ?? "Your Location"}
            </span>
            <img src={RightArrow} alt="home icon" />
            <span>{data?.model}</span>
            <img src={RightArrow} alt="home icon" />
            <span className="current-page-breadcrumb-bg">Book Now</span>
          </div>

          {/* Book now section */}

          <div className="book-now-section">
            <div className="steps-and-chart">
              <div className="progress-container">
                <div className="progress" id="progress"></div>
                <div className="circle active name-below-1">
                  <img src={StepCompleted} alt="step current" />
                  <p>Payment Method</p>
                </div>
                <div className=" name-below-1 ">
                  <img src={StepCompleted} alt="step current" />
                  <p>Verify Details</p>
                </div>
                <div className=" name-below-1 ">
                  <img src={StepCurrent} alt="step current" />
                  <p>Confirmation</p>
                </div>
              </div>

              <div className="reqId-1">
                Request ID:{" "}
                <span className="bold">{first?.finance.process_id}</span>
              </div>
              <div className="mobile-vehicle">
                <div className="vehicle-container">
                  <img
                    crossOrigin="anonymous"
                    src={`${root_url}/${data?.image}`}
                    alt="truck"
                  // width="70%"
                  // height="100%"
                  // width="95%"
                  />
                  <div className="mobile-heading">
                    <h3>{data?.model}</h3>
                    <p className="">
                      {data?.vehicle_type} |{data?.fuel}
                    </p>
                    <div className="price">
                      <h1> ₹{data?.price}</h1>
                      <p>Inclusive of all taxes</p>
                    </div>
                  </div>
                </div>
                <div className="image-container">
                  {first.finance.status.toLowerCase() !== "pending" &&
                    (first.finance.status !== "approved" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="195"
                        height="195"
                        viewBox="0 0 195 195"
                        fill="none"
                      >
                        <path
                          d="M97.5 12.1875L116.594 26.8125L140.156 23.5625L149.094 45.9062L171.438 54.8438L168.188 78.4062L182.812 97.5L168.188 116.594L171.438 140.156L149.094 149.094L140.156 171.438L116.594 168.188L97.5 182.812L78.4062 168.188L54.8438 171.438L45.9062 149.094L23.5625 140.156L26.8125 116.594L12.1875 97.5L26.8125 78.4062L23.5625 54.8438L45.9062 45.9062L54.8438 23.5625L78.4062 26.8125L97.5 12.1875Z"
                          fill="#C34A4A"
                        />
                        <path
                          d="M57.4846 58.4846L51 64.9873L67.7473 81.7346L84.5127 98.5L67.7473 115.265L51 132.013L57.4846 138.515L63.9873 145L80.7346 128.253L97.5 111.487L114.265 128.253L131.013 145L137.515 138.515L144 132.013L127.253 115.265L110.487 98.5L127.253 81.7346L144 64.9873L137.515 58.4846L131.013 52L114.265 68.7473L97.5 85.5127L80.7346 68.7473L63.9873 52L57.4846 58.4846Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="195"
                        height="195"
                        viewBox="0 0 195 195"
                        fill="none"
                      >
                        <path
                          d="M97.5 12.1875L116.594 26.8125L140.156 23.5625L149.094 45.9062L171.438 54.8438L168.188 78.4062L182.812 97.5L168.188 116.594L171.438 140.156L149.094 149.094L140.156 171.438L116.594 168.188L97.5 182.812L78.4062 168.188L54.8438 171.438L45.9062 149.094L23.5625 140.156L26.8125 116.594L12.1875 97.5L26.8125 78.4062L23.5625 54.8438L45.9062 45.9062L54.8438 23.5625L78.4062 26.8125L97.5 12.1875Z"
                          fill="#8BC34A"
                        />
                        <path
                          d="M140.562 59.3125L85.3125 114.563L62.5625 91.8125L51.1875 103.188L85.3125 137.313L151.938 70.6875L140.562 59.3125Z"
                          fill="white"
                        />
                      </svg>
                    ))}
                </div>
              </div>

              <div className="book-now-heading-3">
                <div>
                  <h3>Finance Status...</h3>
                  <p>
                    Calculating your CIBIL Score to check eligibility, can take
                    upto 48hrs
                  </p>
                </div>
                <div className="status">
                  <h1>Status</h1>
                  <span
                    className="pending"
                    style={{
                      backgroundColor:
                        first?.finance.status.toLowerCase() === "pending"
                          ? "#FDEBB0"
                          : first?.finance.status === "approved"
                            ? "#CDFDB0"
                            : "#FDB0B0",
                    }}
                  >
                    {first?.finance.status.charAt(0).toUpperCase() +
                      first?.finance.status.slice(1)}
                  </span>
                </div>
              </div>
              <div className="payment-method-container">
                {first?.finance.status.toLowerCase() !== "pending" ? (
                  <div className="approvedContainer">
                    <div className="content">
                      <div className="one">
                        {first?.finance.status === "approved" ? (
                          <h2>
                            🎉 Congratulations{" "}
                            {first.finance.full_name.split(" ")[0]}! 🎉
                          </h2>
                        ) : (
                          <h2>
                            😔 Sorry {first.finance.full_name.split(" ")[0]}! 😔
                          </h2>
                        )}
                        {first?.finance.status === "approved" ? (
                          <p>
                            According to your CIBIL Score (view it on the side)
                            you are eligible to claim loan upto:
                          </p>
                        ) : (
                          <p>
                            We regret to inform you that your loan application
                            has been declined based on your CIBIL Score (view it
                            on the side). Unfortunately, you are not eligible to
                            claim a loan at this time.
                            <br />
                            <br />
                            Keep in mind that financial circumstances change,
                            and you're welcome to reapply in the future. Best
                            wishes in all your financial endeavors. Feel free to
                            reach out if you need any assistance or advice.
                          </p>
                        )}
                      </div>

                      {first?.finance.status === "approved" && (
                        <div className="two">
                          ₹ {first?.finance?.approved_loan_amount}
                        </div>
                      )}
                      {first?.finance.status === "approved" && (
                        <div className="three">
                          To proceed further with the financing process, feel
                          free to contact your bank to get a bank loan
                          statement.
                        </div>
                      )}
                    </div>
                    <div className="image">
                      {first.finance.status !== "approved" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="195"
                          height="195"
                          viewBox="0 0 195 195"
                          fill="none"
                        >
                          <path
                            d="M97.5 12.1875L116.594 26.8125L140.156 23.5625L149.094 45.9062L171.438 54.8438L168.188 78.4062L182.812 97.5L168.188 116.594L171.438 140.156L149.094 149.094L140.156 171.438L116.594 168.188L97.5 182.812L78.4062 168.188L54.8438 171.438L45.9062 149.094L23.5625 140.156L26.8125 116.594L12.1875 97.5L26.8125 78.4062L23.5625 54.8438L45.9062 45.9062L54.8438 23.5625L78.4062 26.8125L97.5 12.1875Z"
                            fill="#C34A4A"
                          />
                          <path
                            d="M57.4846 58.4846L51 64.9873L67.7473 81.7346L84.5127 98.5L67.7473 115.265L51 132.013L57.4846 138.515L63.9873 145L80.7346 128.253L97.5 111.487L114.265 128.253L131.013 145L137.515 138.515L144 132.013L127.253 115.265L110.487 98.5L127.253 81.7346L144 64.9873L137.515 58.4846L131.013 52L114.265 68.7473L97.5 85.5127L80.7346 68.7473L63.9873 52L57.4846 58.4846Z"
                            fill="white"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="195"
                          height="195"
                          viewBox="0 0 195 195"
                          fill="none"
                        >
                          <path
                            d="M97.5 12.1875L116.594 26.8125L140.156 23.5625L149.094 45.9062L171.438 54.8438L168.188 78.4062L182.812 97.5L168.188 116.594L171.438 140.156L149.094 149.094L140.156 171.438L116.594 168.188L97.5 182.812L78.4062 168.188L54.8438 171.438L45.9062 149.094L23.5625 140.156L26.8125 116.594L12.1875 97.5L26.8125 78.4062L23.5625 54.8438L45.9062 45.9062L54.8438 23.5625L78.4062 26.8125L97.5 12.1875Z"
                            fill="#8BC34A"
                          />
                          <path
                            d="M140.562 59.3125L85.3125 114.563L62.5625 91.8125L51.1875 103.188L85.3125 137.313L151.938 70.6875L140.562 59.3125Z"
                            fill="white"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                ) : (
                  <Stack className="container">
                    <div className="first">
                      <Skeleton
                        variant="rectangular"
                        width={393}
                        height={18}
                        sx={{
                          bgcolor: "grey.300",
                          borderRadius: "24px",
                          "@media  (max-width: 800px)": {
                            width: "80vw", // Use 80% of viewport width
                          },
                        }}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={393}
                        height={18}
                        sx={{
                          bgcolor: "grey.300",
                          borderRadius: "24px",
                          "@media  (max-width: 800px)": {
                            width: "80vw", // Use 80% of viewport width
                          },
                        }}
                      />
                    </div>
                    <div className="second">
                      <Skeleton
                        variant="rectangular"
                        width={612}
                        height={32}
                        sx={{
                          bgcolor: "grey.300",
                          borderRadius: "24px",
                          "@media  (max-width: 800px)": {
                            width: "80vw", // Use 80% of viewport width
                          },
                        }}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={612}
                        height={32}
                        sx={{
                          bgcolor: "grey.300",
                          borderRadius: "24px",
                          "@media  (max-width: 800px)": {
                            width: "80vw", // Use 80% of viewport width
                          },
                        }}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={612}
                        height={32}
                        sx={{
                          bgcolor: "grey.300",
                          borderRadius: "24px",
                          "@media  (max-width: 800px)": {
                            width: "80vw", // Use 80% of viewport width
                          },
                        }}
                      />
                    </div>
                    <div className="third">
                      <Skeleton
                        variant="rectangular"
                        width={445}
                        height={18}
                        sx={{
                          bgcolor: "grey.300",
                          borderRadius: "24px",
                          "@media  (max-width: 800px)": {
                            width: "80vw", // Use 80% of viewport width
                          },
                        }}
                      />
                    </div>
                  </Stack>
                )}
                {first?.finance.status === "approved" ||
                  first?.finance.status.toLowerCase() === "pending" ? (
                  <button
                    className="select-method"
                    style={{
                      cursor: "pointer",
                    }}
                    disabled={first?.finance.status.toLowerCase() === "pending"}
                    onClick={handleProceed}
                  >
                    Proceed
                  </button>
                ) : (
                  <button
                    className="select-method"
                    style={{
                      cursor: "pointer",
                    }}
                    disabled={first?.finance.status.toLowerCase() === "pending"}
                    onClick={handleContact}
                  >
                    Contact Us
                  </button>
                )}
                {first?.finance.status.toLowerCase() !== "pending" && (
                  <div className="message">
                    <h1>Message From Team</h1>
                    <p>
                      {first.finance.message_from_team ??
                        "Feel free to contact us with your Request ID for any further inquiries or doubts you may have."}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="vehicle-details-container">
              <div className="reqId">
                Request ID:{" "}
                <span className="bold">{first?.finance.process_id}</span>
              </div>
              {!!first?.finance.cibil_score && (
                <div className="cibil">
                  <h1>Your CIBIL Score</h1>
                  <Speedometer
                    className="speedometer"
                    value={first?.finance.cibil_score - "0" ?? 750}
                    width={size[0]}
                    height={size[1]}
                  />
                  <p>{first?.finance.cibil_report}</p>
                </div>
              )}

              <div className="vehicle-details">
                <div className="truck-heading">
                  <div className="heading">
                    <h3>{data?.model}</h3>
                    <div className="span-heading">
                      <span>{data?.vehicle_type}</span>
                      <span>{data?.fuel}</span>
                    </div>
                    <div className="sub-details">
                      <span>
                        {" "}
                        {data?.make} | {data?.kms_driven} |{data?.year} |{" "}
                        {data?.weight}
                      </span>
                    </div>
                  </div>
                  <img
                    crossOrigin="anonymous"
                    src={`${root_url}/${data?.image}`}
                    alt="truck"
                  // width="70%"
                  // height="100%"
                  // width="95%"
                  />
                </div>

                <div className="explore-truck-details">
                  <p>
                    <img src={Home2Icon} alt="Users Icon" />
                    Test Drive Available
                  </p>
                  <p>
                    <img src={LocationIcon} alt="Location Icon" />
                    {data?.rto_district}, {data?.rto_state}
                  </p>
                </div>

                <div className="price-container">
                  <div className="explore-truck-price">
                    <span>₹{data?.price}</span>
                    <span>₹{data?.price}</span>
                  </div>
                  <span className="inclusive">inclusive of all taxes</span>
                </div>
              </div>

              <div className="emi-calculator">
                <h3>EMI Calculator</h3>

                <p>
                  No matter how you like to buy, we have an option that works
                  for you. Low monthly payments, and the interest is covered
                  with No Cost EMI.
                </p>

                <button className="" onClick={openPopup}>
                  Calculate EMI
                </button>
              </div>
              <div>
                <div id="overlay" className={`popup-${visibility}`}></div>
                <div id="popup" className={`popup popup-${visibility}`}>
                  <div className="popup-container">
                    <div className="heading-1">
                      <h1>EMI Calculator</h1>
                      <button className="btn-close" onClick={closePopup}>
                        <img src={Cross} alt="" />
                      </button>
                    </div>
                    <h3>
                      No matter how you like to buy, we have an option that
                      works for you. Low monthly payments, and the interest is
                      covered with No Cost EMI.
                    </h3>
                    <div className="grid-fields">
                      <div className="select-container">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M5 2.5H15"
                              stroke="#818D9D"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5 6.66669H15"
                              stroke="#818D9D"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5 10.8333L12.0833 17.5"
                              stroke="#818D9D"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5 10.8333H7.5"
                              stroke="#818D9D"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.5 10.8333C13.0558 10.8333 13.0558 2.5 7.5 2.5"
                              stroke="#818D9D"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>

                        <input
                          type="number"
                          placeholder="Amount"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </div>
                      <div className="select-container">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M12.4224 0.546371C12.2608 0.606092 12.0746 0.746613 11.9622 0.894157C11.8638 1.02765 4.26528 15.9613 4.1915 16.1721C3.96667 16.8079 4.45849 17.5 5.13649 17.5C5.25945 17.5 5.4351 17.4719 5.52995 17.4403C5.90935 17.3138 5.67398 17.7459 9.78768 9.6485C11.8568 5.56993 13.6028 2.13424 13.666 2.00777C13.7644 1.81104 13.7819 1.74078 13.7819 1.51244C13.7854 1.2841 13.7714 1.21735 13.6801 1.0417C13.5606 0.809845 13.3252 0.602579 13.1004 0.535833C12.9248 0.483139 12.5875 0.490164 12.4224 0.546371Z"
                              fill="#818D9D"
                            />
                            <path
                              d="M3.26057 1.69166C1.81673 1.93757 0.601242 2.99498 0.172659 4.38612C-0.638839 7.01383 1.52515 9.66261 4.25825 9.38509C5.89178 9.21998 7.20915 8.09231 7.64476 6.48337C7.73258 6.1672 7.73961 6.07937 7.73961 5.52081C7.73961 4.96576 7.72907 4.87091 7.64476 4.55474C7.59207 4.36153 7.47262 4.04536 7.37777 3.85215C6.82624 2.72097 5.81099 1.94109 4.58847 1.70923C4.29338 1.65302 3.54512 1.64248 3.26057 1.69166ZM4.31797 3.75027C5.00651 3.91187 5.58264 4.53718 5.69506 5.25031C5.78288 5.81941 5.59318 6.38852 5.18216 6.79953C4.79925 7.18245 4.4058 7.34756 3.87885 7.34756C3.37298 7.34756 2.95845 7.17542 2.60013 6.8171C2.02751 6.24448 1.8905 5.44704 2.2418 4.73039C2.37178 4.47043 2.61418 4.17534 2.82496 4.03131C3.2711 3.72568 3.79805 3.62732 4.31797 3.75027Z"
                              fill="#818D9D"
                            />
                            <path
                              d="M13.6449 8.61566C12.8474 8.69997 11.9832 9.11099 11.4036 9.6836C10.8661 10.2141 10.5324 10.7902 10.3356 11.5139C10.2478 11.83 10.2408 11.9179 10.2408 12.4764C10.2408 13.0315 10.2513 13.1263 10.3356 13.4425C10.5218 14.131 10.9293 14.809 11.4528 15.3044C12.0043 15.8278 12.735 16.1861 13.4973 16.3056C13.9224 16.3758 14.6882 16.3337 15.1027 16.2177C16.385 15.8664 17.4178 14.8688 17.8077 13.6111C18.5244 11.2925 16.9295 8.87562 14.5126 8.61566C14.1156 8.5735 14.0524 8.5735 13.6449 8.61566ZM14.5582 10.6989C15.1308 10.8499 15.6192 11.3031 15.8334 11.8792C16.0548 12.4799 15.8756 13.2704 15.4119 13.7341C15.0676 14.0818 14.6742 14.2645 14.2069 14.2926C13.0547 14.3664 12.1132 13.3406 12.2853 12.2024C12.3907 11.5139 12.9493 10.8815 13.6027 10.7024C13.8381 10.6391 14.3334 10.6356 14.5582 10.6989Z"
                              fill="#818D9D"
                            />
                          </svg>
                        </span>
                        <input
                          type="number"
                          value={rate}
                          onChange={(e) => setRate(e.target.value)}
                          placeholder="Rate of Interest"
                        />
                      </div>

                      <button onClick={handleClick}>Calculate</button>
                    </div>
                    <div className="terms">
                      <h4>Terms</h4>
                      <h4>Monthly Installments</h4>
                    </div>
                    {months}

                    <div className="warn" style={{ marginTop: "1rem" }}>
                      <h3>Disclaimer</h3>
                      <p>
                        The calculation performed by this EMI Calculator is
                        based on the information you entered and is for
                        illustrative purposes only. This calculation reflects
                        amounts in Indian Rupee rounded to the nearest whole
                        figure. Actual EMI may differ marginally due to interest
                        for broken period based on due dates for loan. Actual
                        payments may vary depending on conditions applicable at
                        the time of signing the contract. Estimated monthly
                        payments DO NOT include Insurance Provision amount. The
                        final Loan amount and EMI may marginally differ location
                        to location.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Price calculation section ends */}

          <ContactStrip show={false} />

          <Footer />
        </FinanceWrapper>
      ) : (
        <div
          style={{
            margin: "1rem auto",
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            color="inherit"
            style={{ color: "#1E2130", margin: "1rem auto" }}
            size={70}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default Finance;

export const RateCard = ({ amount, rate, installments, hideNoCost }) => {
  let total;
  if (
    !isNaN(amount) &&
    !isNaN(rate) &&
    !isNaN(installments) &&
    amount !== "" &&
    installments !== "" &&
    rate !== ""
  ) {
    const year = installments / 12;
    const interest = parseInt(amount) * (parseInt(rate) * 0.01) * year;
    total = (parseInt(amount) + parseInt(interest)) / installments;
  }
  return (
    <div className="carddContainer">
      <div className="details">
        {
          !hideNoCost &&
          <h4>No cost EMI</h4>
        }
      </div>

      <div className="bottom_sec" >
        <div className="first">
          <p>Total Interest Payment</p>
          <span className='first_span'>₹ {(installments*total)-amount}</span>
        </div>
        <div className="first">
          <p>Total Amount to Pay</p>
          <span className='second_span'>₹{installments*total}</span>
        </div>
        <div className="second">
          <div className="main">
            <span className='head'>EMI</span>
            <span className='text'>Monthly Payment</span>
          </div>
          <div className="main2">
            <span className='price'>₹{total.toFixed(2)} </span>
            <span className='text2'>Calculated on On Road Price</span>
          </div>
        </div>
      </div>

    </div>
  );
};
