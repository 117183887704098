import React from "react";
import { Link } from "react-router-dom";
import "./BreadCrumbs.css"
import { ReactComponent as HomeIcon }  from "../../assets/homeIcon.svg"
import {ReactComponent as Separator} from "../../assets/breadCrumRightArrow.svg"

const BreadCrumbs = ({ breadcrumbs }) => {
  return (
    <div className="breadcrumbs">
      {breadcrumbs.map((breadcrumb, index) => (
        <span>
          {index > 0 && <span className="separator"><Separator/></span>}
           
          {breadcrumb.label === "Home" ? <Link to={breadcrumb.link}><HomeIcon/></Link>:

          breadcrumb.link ? (
            <Link className="link-style" to={breadcrumb.link}>{breadcrumb.label}</Link>
          ) : (
            <span className="active-breadcrumb">{breadcrumb.label}</span>
          )}
        </span>
      ))}
    </div>
  );
};

export default BreadCrumbs;
