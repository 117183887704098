import React, { useState } from "react";
import { FaqItem } from "./index";
import Wrapper from "../wrappers/Common";
const faqs = [
  {
    id: 1,
    header: " Why buy a used truck from Cavalo?",
    text: `With complete confidence and no compromises, Cavalo removes the danger and uncertainty associated with purchasing a secondhand truck. Only the best quality certified used trucks are offered in your location, thanks to our background check. A truck that is certified by Cavalo Assured must go through an extensive inspection that looks at the condition of every component of the truck before it can be certified. Being Cavalo Assured needs perfection. All Cavalo Guaranteed trucks have simple paperwork, free RC transfers, and vehicle financing choices with cheap interest rates starting at XX%. You can buy a truck that is ideal for your business and work by going through a transparent, reasonable, and safe approach.
`,
  },
  {
    id: 3,
    header: " What makes cavalo the best place to sell my truck?",
    text: `Cavalo is on a mission to streamline the unorganized truck buying/selling market in India. We will help you: 
Get the best price in the market: Shop with confidence on our platform knowing that you will get the best possible deal available.
Faster Buying & Selling: Quick & easy buying & selling process with Efficient & convenient transaction process.
Professional Dealers: We have verified & approved professional dealers on our platform, so you don’t have to worry about trustability. 
`,
  },

  {
    id: 2,
    header: "Will Cavalo help me in arranging for finance?",
    text: `Buyers may choose to use financing from Cavalo, in which case we would work with our finance partners to finalize the transaction. Our long-standing relationships enable us to simplify loan processing and secure lower interest rates for our clients. In comparison to the market rate of XX–XX%, you can obtain used auto loans with Cavalo at interest rates as low as XX%, depending on your creditworthiness.
`,
  },
  {
    id: 4,
    header: "How long does it take to sell my truck?",
    text: `It only takes upto 30 minutes to answer the questions, fill up the form & upload the documents to get the final quotation, after which our team will evaluate the condition of your truck in person & the amount will be transferred to your bank account.
`,
  },
  {
    id: 5,
    header: "What all factors impact the prices of my truck?",
    text: `Truck Condition:
Your car’s basic details: Like variant, age, production status, ownership serial, registration state, insurance status, etc. Our certified inspection report captures the as-is condition of your car- Any additional features you have installed or any refurbishment work needed in your car

Market Demand: 
Market demand is based upon our active users who bid/buy the trucks from our platform which is a network of over XXXX+ customers, hence assuring the best price for your truck.
.
`,
  },
  {
    id: 6,
    header: "How much fee do you charge for RC Transfer?",
    text: `Zero, yes RC transfer is free when buying a cavalo-assured truck. Buying with cavalo also ensures no need to wait for the long queue at RTO for RC Transfer & get it done at the luxury of your home.`,
  },
];
const Faq = () => {
  const [active, setActive] = useState(null);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };
  return (
    <Wrapper>
      <div className="faq-section">
        <h3>Frequently Asked Questions</h3>
        <div className="card">
          <div className="card-body">
            {faqs.map((faq, index) => {
              return (
                <FaqItem
                  key={index}
                  active={active}
                  handleToggle={handleToggle}
                  faq={faq}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Faq;
