import React, { useRef, useState } from "react";
import Account from "../assets/Account.svg";
import Location from "../assets/location.svg";
import Buy from "../assets/buy.svg";
import Sell from "../assets/sell-icon.png";

// import styled from "styled-components";
import "./AccountPopup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEnvelope,
  faGreaterThan,
  faLock,
  faMobile,
  faMobileScreen,
  faSearch,
  faUnlockKeyhole,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { get } from "../utils/functions";
import Lock from "../assets/Lock.svg";
import Phone from "../assets/Phone.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useOnClickOutside from "../utils/useOnClickOutside";
import { BiLogOut } from "react-icons/bi";

function LoginPopup(props) {
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isSend, setisSend] = useState(false);
  const [login, setlogin] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, props.onClose, "btnbtn");
  const account = JSON.parse(localStorage.getItem("account"));
  const navigate = useNavigate();

  const handleLogout = (event) => {
    localStorage.clear();
    window.location.reload(true);
  };
  const handleBuy = (event) => {
    navigate("/MyFinance");
  };
  const handleSell = (event) => {
    navigate("/MyListing");
  };

  return (
    <div className="login-popup-1">
      <div ref={ref} className="login-popup-inner-1">
        <div className="person">
          <img src={Account} alt="" />
          <div>
            <h3>{account.name}</h3>
            <h4>{account.email}</h4>
          </div>
        </div>
        <div className="person-1">
          <img src={Phone} alt="phone" />

          <h4>{account.mobile_no}</h4>
        </div>
        <div className="person-1">
          <img src={Location} alt="phone" />

          <h4>
            {account.district}, {account.state}
          </h4>
        </div>

        <div className="buy-seller">
          <button type="submit" className="buy" onClick={handleBuy}>
            <img src={Buy} alt="Buy" />
            <p>Buy Trucks</p>
          </button>
          <button type="submit" className="sell" onClick={handleSell}>
            <img src={Sell} alt="Sell" />
            <p>Sell Trucks</p>
          </button>
        </div>

        <button type="submit" onClick={handleLogout} className="submit">
          <BiLogOut />
          <p>

          Logout
          </p>
        </button>
      </div>
    </div>
  );
}

export default LoginPopup;
