import React from "react";

import styled from "styled-components";

import nisha from "../../assets/nisha.jpg";

import "./InfoCard.css";

const ProfileInfoCard = ({ item }) => {
  console.log(item);

  return (
    <div className="info_card">
      <div className="profile-container">
        <div className="pic-name-section">
          <div className="profile-pic-container">
            <img className="picture" src={item?.image} />
          </div>
          <div className="">
            <p className="sub_heading2 heading mt-15">
              {item?.name}
            </p>
            <p className="sub_heading3 -mt-15">
            {item?.designation}
            </p>
          </div>
        </div>
        <p className="profile-info-text">{item?.info}</p>
      </div>
    </div>
  );
};

export default ProfileInfoCard;
