import React, { useEffect, useState } from "react";
import buyGuide from "../assets/buyGuide.png";
import buyGuideText from "../assets/buyGuideText.png";
import { RateCard } from "../pages/Finance3";
import styled from "styled-components";
import { Slider } from "@mui/material";
import EmiCalculator from "./EmiCalculator";

const Wrapper = styled.div`
  .popup-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0 auto;
    padding: 2rem 7.5%;

    gap: 50px;
    justify-content: space-between;

    background:
		/* Shadow covers */ linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */
        radial-gradient(
          50% 0,
          farthest-side,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
      radial-gradient(
          50% 100%,
          farthest-side,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        0 100%;
    background: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), #ffffff 66%) 0 100%,
      radial-gradient(
        farthest-side at 50% 0,
        rgba(34, 34, 34, 0.5),
        rgba(0, 0, 0, 0)
      ),
      radial-gradient(
          farthest-side at 50% 100%,
          rgba(34, 34, 34, 0.5),
          rgba(0, 0, 0, 0)
        )
        0 100%;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-attachment: local, local, scroll, scroll;
    background-size: 100% 87px, 100% 87px, 100% 29px, 100% 29px;
  }
  .popup-container h1 {
    margin: 1rem auto;
    width: fit-content;
  }
  .heading-emi {
    display: flex;
    justify-content: space-between;
    /* min-width: 35vw;  */
  }
  .heading-emi {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .heading-emi h1 {
    background: -webkit-linear-gradient(
      15deg,
      #f6af33 0%,
      #f8cf17 40%,
      #f6af33 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Mulish;
    font-size: 64px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-left: 0 !important;
  }
  .heading-emi h3 {
    font-family: Mulish;
    font-size: 22px;
    margin: 0;
  }
  .grid-fields {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 18px;
    margin: 0 auto;
    margin-top: 14px;
  }
  .grid-fields button {
    display: flex;
    padding: 14px 18px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    color: var(--basic-white, #fff);
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    border-radius: 8px;
    background: #1e2130;
    margin: auto 0; /* 24px */
  }
  .select-container-1 {
    border-radius: 8px;
    background: var(--field-off-white, #dfdfdf);
    box-sizing: border-box;
    display: block;
    cursor: pointer;
    height: 54px;
    display: flex;
    padding: 14px 6px 14px 18px;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
  }

  .new-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    max-width: 1063px;
    height: fit-content;
    margin: 0 auto;
    padding: 4rem 6rem;
    background-color: #1e2130;
    border-radius: 12px;
    box-sizing: border-box;
  }
  .slider-flex {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .slider-flex h1 {
    color: #c27d15;
    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }

  .inner-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: start;
    gap: 50px;
  }
  .inner-flex .select-container-1 {
    width: 100%;
    min-width: 400px;
    max-width: 500px;
  }
  .inner-flex-2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    justify-content: start;
  }
  .inner-flex-2 button {
    width: 100%;
    max-width: 400px;
    background-color: #f6af33;
    color: white;
    padding: 14px 18px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 800;
    height: 54px;
    cursor: pointer;
  }
  .inner-flex-2 button:hover {
    width: 100%;
    max-width: 400px;
    background-color: #c48923;
    color: white;
    padding: 14px 18px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 800;
    height: 54px;
    cursor: pointer;
  }
  .select-container-1 input {
    border: none;
    width: 100%;
    height: 100%;
    background-color: inherit;
    /* box-sizing: border-box; */
    display: block;
    outline: none;
    color: #757575;
    font-weight: 400;
    font-size: 16px;
  }
  .select-container-1 span {
    width: 17.98px;
    height: 17px;
  }
  .terms {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .terms h4 {
    margin: 0;
  }
  .warn {
    color: #616161;
  }

  .carddContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #c4c4c4;
    padding: 3rem 0;
  }
  .details h4 {
    color: #c27d15;
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }
  .details {
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex-shrink: 0;
  }
  .details h1 {
    color: var(--basic-off-navy-blue, #1e2130);
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
  .details p {
    color: #818d9d;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
  .flex-container-temp {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  .price h1 {
    color: var(--basic-off-navy-blue, #1e2130);
    font-family: Mulish;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.56px;
    margin: 0;
  }
  .price p {
    color: var(--gray, #818d9d);
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }

  @media (max-width: 841px) {
    .heading-emi {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .heading-emi h1 {
      color: var(--basic-off-navy-blue, #1e2130);
      font-family: Mulish;
      font-size: 42px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin-left: 0;
    }
    .details {
      display: flex;
      width: fit-content;
      padding: 0px;
      justify-content: space-between;
      /* align-items: center; */
      flex-direction: column;
    }
    .details h1 {
      color: var(--basic-off-navy-blue, #1e2130);
      font-family: Mulish;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .details p {
      color: #818d9d;
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .inner-flex {
      flex-direction: column;
    }
    .new-container {
      padding: 3rem 3.5rem;
    }
    .heading-emi h3 {
      font-family: Mulish;
      font-size: 16px;
      margin: 0;
    }
    .popup-container {
      gap: 25px;
      justify-content: space-between;
    }
    .inner-flex {
      gap: 25px;
    }
    .new-container {
      padding: 20px 25px;
    }
  }
  @media (max-width: 500px) {
    .grid-fields {
      display: flex;
      align-items: flex-start;
      gap: 18px;
      margin-top: 14px;
      flex-direction: column;
      width: 100%;
    }
    .grid-fields .select-container,
    .grid-fields button {
      width: 100%;
    }
    .heading-emi h3 {
      font-family: Mulish;
      font-size: 16px;
      margin: 0;
    }
    .popup-container {
      gap: 25px;
      justify-content: space-between;
    }
    .inner-flex {
      gap: 25px;
    }
    .new-container {
      padding: 20px 25px;
    }
    .select-container-1 {
      width: 100%;
    }
    .carddContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    .card-container .details h4 {
      color: #c27d15;
      font-family: Mulish;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0;
    }
    .details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      flex: 1 0 0;
      width: fit-content !important;
      margin: 0;
      padding: 0;
    }
    .details h1 {
      color: var(--basic-off-navy-blue, #1e2130);
      font-family: Mulish;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
    }
    .details p {
      color: #818d9d;
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
    .price h1 {
      font-size: 20px;
    }
    .heading-emi h1 {
      font-size: 30px;
    }
  }
`;

const BuySteps = () => {
  const [rate, setRate] = useState(10);
  const [months, setMonths] = useState("");
  const [amount, setAmount] = useState(500000);
  const [emi, setEmi] = useState();

  useEffect(() => {
    // //"hello");

    if (
      !isNaN(amount) &&
      !isNaN(rate) &&
      !isNaN(months) &&
      amount !== "" &&
      months !== "" &&
      rate !== ""
    ) {
      const year = months / 12;
      const interest = parseInt(amount) * (parseInt(rate) * 0.01) * year;
      const total = (parseInt(amount) + parseInt(interest)) / months;
      //interest, total,year);
      setEmi(total);
    }
  }, [amount, months, rate]);
  const arr = [3, 6, 9, 12, 24, 36, 48, 60, 72, 84];
  const handleClick = () => {
    setMonths(
      arr.map((month) => {
        return <RateCard amount={amount} rate={rate} installments={month} />;
      })
    );
  };
  return (
    <Wrapper>
      <div className="popup-container">
        <div className="flex-container-temp heading-emi">
          <h1>EMI Calculator</h1>
          <EmiCalculator />


        </div>



      </div>
    </Wrapper>
  );
};

export default BuySteps;
