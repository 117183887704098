import React, { useRef } from "react";
import styled from "styled-components";
import ArrowDown from "../assets/ArrowDown.svg";
const Wrapper = styled.div`
  .text-primary {
    color: #5a8dee !important;
  }

  .rc-faq-toggle {
    align-items: flex-start;
    cursor: pointer;
    padding: 1rem;
    margin: 2.4rem 0;
    border: 1px solid #eeeeee;
    border-radius: 0.8rem;
  }
  .rc-faq-title-wrapper {
    padding: 0 1.5rem;
  }
  .rc-faq-toggle .rc-faq-title-wrapper {
    display: flex;
    justify-content: space-between;
    transition: 0.3s;
  }

  .rc-faq-toggle.active .rc-faq-icon {
    transform: rotate(180deg);
  }

  .rc-faq-title {
    font-weight: 700;
    font-size: 22px;
    position: relative;
    color: #000000;
    transition: 0.3s;
    width: 100%;
    margin: 2rem 0;
  }
  .rc-faq-toggle.active .rc-faq-title {
    margin-bottom: 0;
  }
  .rc-faq-icon {
    position: relative;
    top: 2px;
    color: #475f7b;
    transition: 0.35s;
    font-size: 12px;
  }
  .rc-faq-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 15px;
  }
  .rc-faq-body p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #727e8c;
  }
  .rc-collapse {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 6s ease-in 2s !important;
    /* transition-delay: 2s; */

  }
  .rc-collapse.show {
    height: fit-content !important;
  }
  @media (max-width: 1000px){
    .rc-faq-title{
      font-size: 2.3rem;
      margin: 1rem auto;
    }
    .rc-faq-body p {
      font-size: 11px !important;
    }
    .rc-faq-title {
      font-size: 16px;
    }

  }
`;

const FaqItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, faq } = props;
  const { header, id, text } = faq;

  return (
    <Wrapper>
      <div className="rc-faq-card">
        <div className="rc-faq-header">
          <div
            className={`rc-faq-toggle p-3 ${active === id ? "active" : ""}`}
            onClick={() => handleToggle(id)}
          >
            <div className="rc-faq-title-wrapper">
              <h5 className="rc-faq-title">{header}</h5>
              <img src={ArrowDown} className=" rc-faq-icon" />
            </div>
            <div
              ref={contentEl}
              className={`rc-collapse ${active === id ? "show" : ""}`}
              style={
                active === id
                  ? { height: contentEl.current.scrollHeight }
                  : { height: "0px" }
              }
            >
              <div className="rc-faq-body">
                <p className="mb-0">{text}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default FaqItem;
