import React from "react";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";

import "./PrivacyPolicy.css";

const PrivacyPolicyComponent = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Privacy and Policy - Cavalo" },
  ];

  return (
    <div className="wrapper">
      <BreadCrumbs breadcrumbs={breadcrumbs} />

      <h2 className="terms-condition-heading-style">
        Privacy And Policy - Cavalo
      </h2>

      <p className="privacy-policy-text">
        Cavalo, referred to as "us," "we," or "our," is the operator of the
        website <a href="https://cavalo.in/"> https://cavalo.in/ </a> (the
        "Service").
      </p>

      <p className="privacy-policy-text">
        This page aims to inform you about our policies concerning the
        collection, utilization, and disclosure of personal data when engaging
        with our Service, as well as the choices available to you in connection
        with this data.
      </p>

      <p className="privacy-policy-text">
        Your use of the Service implies your agreement with the collection and
        utilization of information as outlined in this policy. Unless explicitly
        defined in this Privacy Policy, terms used herein have the same meanings
        as those in our Terms and Conditions, accessible from{" "}
        <a href="https://cavalo.in/"> https://cavalo.in/ </a>.
      </p>

      <div>
        <h3 className="privacy-policy-heading">
          Information Collection And Use
        </h3>

        <p className="privacy-policy-text">
          We gather various types of information for diverse purposes, all aimed
          at enhancing and improving our Service.
        </p>

        <h3 className="privacy-policy-heading">Types of Data Collected</h3>

        <p className="privacy-policy-text">
          <span className="privacy-policy-sub-heading">Personal Data:</span>{" "}
          While using our Service, you may be prompted to provide specific
          personally identifiable information, including but not limited to your
          email address, first and last name, phone number, address, state,
          province, ZIP/postal code, and city.
        </p>

        <p className="privacy-policy-text">
          <span className="privacy-policy-sub-heading">
            Cookies and Usage Data:
          </span>{" "}
          Additionally, we collect Usage Data, encompassing information on how
          the Service is accessed and used. This may include your computer's IP
          address, browser type, browser version, the pages of our Service
          visited, the time and date of your visit, time spent on those pages,
          unique device identifiers, and other diagnostic data.
        </p>

        <p className="privacy-policy-text">
          <span className="privacy-policy-sub-heading">
            Tracking & Cookies Data:{" "}
          </span>{" "}
          We employ cookies and similar tracking technologies to monitor the
          activity on our Service and store certain information. Cookies are
          files with small data amounts, including an anonymous unique
          identifier, sent to your browser from a website and stored on your
          device.
        </p>

        <p className="privacy-policy-text">
          You can instruct your browser to reject all cookies or indicate when a
          cookie is being sent. However, failure to accept cookies may limit
          your ability to use specific portions of our Service. Examples of
          Cookies we use include Session Cookies, Preference Cookies, and
          Security Cookies.
        </p>

        <h3 className="privacy-policy-heading">Use of Data</h3>

        <p className="privacy-policy-text">
          Cavalo utilizes the collected data for various purposes, including:
        </p>

        <ol>
          <li className="privacy-policy-text">
            Providing and maintaining the Service
          </li>
          <li className="privacy-policy-text">
            Notifying you about changes to our Service
          </li>
          <li className="privacy-policy-text">
            Enabling participation in interactive features of our Service
          </li>
          <li className="privacy-policy-text">
            Offering customer care and support
          </li>
          <li className="privacy-policy-text">
            Providing analysis or valuable information to improve the Service
          </li>
          <li className="privacy-policy-text">
            Monitoring the usage of the Service
          </li>
          <li className="privacy-policy-text">
            Detecting, preventing, and addressing technical issues
          </li>
        </ol>

        <h3 className="privacy-policy-heading">Transfer of Data</h3>

        <p className="privacy-policy-text">
          Your information, including Personal Data, may be transferred to and
          maintained on computers located outside your state, province, country,
          or other governmental jurisdiction. This transfer is subject to data
          protection laws that may differ from those in your jurisdiction.
        </p>

        <p className="privacy-policy-text">
          Your consent to this Privacy Policy, followed by the submission of
          information, signifies your agreement to such transfer. Cavalo assures
          that all reasonable steps will be taken to treat your data securely
          and in line with this Privacy Policy.
        </p>

        <h3 className="privacy-policy-heading">Disclosure of Data</h3>

        <p className="privacy-policy-text">
          In adherence to legal obligations, Cavalo may disclose your Personal
          Data in good faith, including actions necessary to comply with legal
          obligations, protect and defend our rights or property, investigate
          potential wrongdoing, safeguard the personal safety of users, or
          protect against legal liability.
        </p>

        <h3 className="privacy-policy-heading">Security of Data</h3>

        <p className="privacy-policy-text">
          While the security of your data is a priority, it's essential to
          acknowledge that no method of transmission over the Internet or
          electronic storage is entirely secure. Cavalo strives to use
          commercially acceptable means to protect your Personal Data, yet
          absolute security cannot be guaranteed.
        </p>

        <h3 className="privacy-policy-heading">Service Providers</h3>
        <p className="privacy-policy-text">
          Third-party companies and individuals may be employed to facilitate
          our Service, perform Service-related services, or assist in analyzing
          how our Service is used. These entities have access to Personal Data
          solely to perform tasks on our behalf and are obligated not to
          disclose or use it for any other purpose.
        </p>

        <h3 className="privacy-policy-heading">Analytics</h3>
        <p className="privacy-policy-text">
          We may utilize third-party Service Providers to monitor and analyze
          the use of our Service. Google Analytics is one such service that
          tracks and reports website traffic. Users can opt out of having their
          activity on the Service made available to Google Analytics.
        </p>

        <p className="privacy-policy-text">
          For more details on Google's privacy practices, refer to the Google
          Privacy & Terms webpage.
        </p>

        <h3 className="privacy-policy-heading">Links To Other Sites</h3>
        <p className="privacy-policy-text">
          Our Service may include links to third-party sites not operated by us.
          It is strongly recommended to review the Privacy Policy of every
          visited site, as we have no control over, and assume no responsibility
          for, the content, privacy policies, or practices of third-party sites
          or services.
        </p>

        <h3 className="privacy-policy-heading">Children's Privacy</h3>
        <p className="privacy-policy-text">
          Our Service does not address individuals under the age of 18
          ("Children"). We do not knowingly collect personally identifiable
          information from individuals under 18. Parents or guardians who
          believe their Children have provided Personal Data are encouraged to
          contact us, and we will take steps to remove such information from our
          servers.
        </p>

        <h3 className="privacy-policy-heading">
          Changes To This Privacy Policy
        </h3>
        <p className="privacy-policy-text">
          Our Privacy Policy may undergo periodic updates, and users will be
          notified of changes via email and/or a prominent notice on our
          Service. It is advised to review this Privacy Policy periodically for
          awareness of any alterations. Changes become effective upon posting on
          this page.
        </p>

        <h3 className="privacy-policy-heading">Contact Us</h3>
        <p className="privacy-policy-text">
          For inquiries about this Privacy Policy, please reach out to us:
        </p>

        <ol>
          <li className="privacy-policy-text">By email: info@cavalo.in</li>
          <li className="privacy-policy-text">By Call: 7021411346</li>
        </ol>
      </div>
    </div>
  );
};

export default PrivacyPolicyComponent;
