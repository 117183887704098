import React from "react";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import { ReactComponent as Contact } from "../../assets/CallIcon.svg";
import { ReactComponent as PersonalDetail } from "../../assets/personalDetail.svg";
import { ReactComponent as TruckSvg } from "../../assets/trucksvg.svg";
import { ReactComponent as BusSvg } from "../../assets/BusSvg.svg";
import { ReactComponent as FinanaceSvg } from "../../assets/finance-svgrepo-com.svg";
import "./VehicleRequirement.css";
import PersonalDetailSection from "./PersonalDetailsSection";
import VehicleDetailsSection from "./VehicleDetailsSection";
import FinanceSection from "./FinanceSection";
import SubmitSection from "./SubmitSection";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import qs from "qs";
import toast from "react-hot-toast";
import { root_url } from "../../utils/url.constant";

const VehicleRequirementComponent = () => {
  const navigationSection = [
    {
      icon: <PersonalDetail />,
      text: "Personal Details",
    },
    {
      icon: <TruckSvg />,
      text: "Vehicle Details",
    },
    {
      icon: <FinanaceSvg />,
      text: "Finance",
    },
  ];

  const [activeSection, setActiveSection] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [makeList, setMakeList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [bodyType, setBodyType] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [model, setModel] = useState([]);
  const [payload, setPayload] = useState([]);


  const [data, setData] = useState({
    fullName: "",
    mobileNumber: "",
    email: "",
    state: "",
    district: "",
    type: "Truck+,Pickup+,Trailer+,Mini Truck+,Transit Mixer+,Tipper",
    make: "",
    model: "",
    bodyType: "",
    length: "",
    capacity: "",
    manufactureYear: "",
    additionalInfo: "",
    range: "",
    financingOption: "",
    duration: "",
    isAuction: "yes",
  });

  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Vehicle Requirements" },
  ];




  useEffect(() => {
    axios
      .get(`${root_url}/vehicle/brands?type=${data.type}`)
      .then((res) => {
        console.log("make data", res.data)
        setMakeList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${root_url}/truck/body-type`)
      .then((res) => {
        setBodyType(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${root_url}/truck/payload`)
      .then((res) => {
        setPayload(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${root_url}/general/rto/state`)
      .then((res) => {
        setState(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (data?.state?.id) {
      axios
        .get(`${root_url}/general/rto/code/state/${data.state.id}`)
        .then((res) => {
          console.log(res.data.data); // Logging the data received from the API
          setDistrict(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }


    if (data?.make?.brand_id) {
      console.log("calling model api", data.make)
      axios
        .get(`${root_url}/vehicle/models/brands/${data.make.brand_id}?type=${data.type}`)
        .then((res) => {
          console.log(res.data)
          // Filter out duplicate model names

          setModel(res.data.data);

        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [data]);

  const activeSectionChangeHandler = (from) => {
    if (from === "back") {
      setActiveSection((prev) => prev - 1);
    } else {
      if (!handleDisbaled()) {
        setActiveSection((prev) => prev + 1);
      } else {
        toast.error("Please fill all the detail");
      }
    }
  };

  const appendSpreadsheet = async () => {


    if (handleDisbaled()) {
      toast.error("Please fill all the detail");
      return
    }

    try {
      let dataObj = qs.stringify({
        full_name: data?.fullName,
        email: data.email,
        phone: data.mobileNumber,
        state: data?.state?.id,
        district: data.district,
        vehicle_category: data.type,
        make: data?.make?.brand_id,
        model: data?.model,
        body_type: data?.bodyType,
        length: data?.length,
        ton_capacity: data?.capacity,
        year_of_manufacture: data?.manufactureYear,
        additional_requirements: data?.additionalInfo,
        minimum_budget: data?.range?.minValue,
        maximum_budget: data?.range?.maxValue,
        financing_option: data?.financingOption,
        time_duration_for_purchase: data?.duration,
        is_interested_in_auctions: data?.isAuction,
        is_existing_customer: "false",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${root_url}/buy/interest`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: dataObj,
      };

      axios
        .request(config)
        .then((response) => {

          if (response.data?.status_code === 400) {
            toast.error(response?.data?.message);
          } else {
            setIsSubmitted(true);

          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const onChnageHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;


    if (name === "make") {
      value = makeList.filter((item) => item.brand_id == value)[0];
    }
    if (name === "type") {
      // Fetch makes based on the selected type
      axios
        .get(`${root_url}/vehicle/brands?type=${value}`)
        .then((res) => {
          console.log("Make data", res.data);
          // Update the makeList state with the fetched makes
          setMakeList(res.data.data);
          // Proceed with updating the data state
          setData({ ...data, [name]: value });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // For other fields, directly update the data state
      setData({ ...data, [name]: value });
    }


    if (name === "state") {
      value = state.filter((item) => item.id == value)[0];
    }

    setData({ ...data, [name]: value });
  };

  const handleDisbaled = () => {

    let disbaled = true

    if (activeSection === 0) {
      disbaled = data.fullName && data.mobileNumber && data.state?.id && data.district
    }

    if (activeSection === 1) {
      disbaled = data.make && data.model && data.capacity && data.bodyType && data.length && data.manufactureYear
    }

    if (activeSection === 2) {
      disbaled = data.range && data.financingOption && data.duration && data.isAuction
    }
    return !disbaled

  }

  return (
    <div className="wrapper">
      <BreadCrumbs breadcrumbs={breadcrumbs} />
      <div className="vehicle-req-container">
        <h2>Wheels and Wonders: Find your Perfect Vehicle!</h2>
        <p>
          Please fill out the forms below to help us understand your
          requirements and we will provide you with the best options
        </p>
        <div className="form-navigation-content-mobile">
          {navigationSection.map((item, index) => (
            <>
              <div
                className={`svg-text-box  ${activeSection === index ? "isActive" : "isDisable"
                  }`}
              >
                <div className="">{item.icon}</div>

                <h3 style={{ margin: "1px", whiteSpace: "nowrap" }}>
                  {item.text}
                </h3>
              </div>
              {index === 2 ? "" : <hr className="new1" />}{" "}
            </>
          ))}
        </div>


        <div className="vehicle-form-container">
          <div className="form-navigation-container">
            {!isSubmitted && (
              <button
                onClick={() =>
                  activeSection > 0 && activeSectionChangeHandler("back")
                }
                className={` ${activeSection === 0 ? "disbaled-btn" : "enabled-btn"
                  }`}
              >
                {"<"} Back
              </button>
            )}

            <div className="form-navigation-content">
              {navigationSection.map((item, index) => (
                <>
                  <div
                    className={`svg-text-box  ${activeSection === index ? "isActive" : "isDisable"
                      }`}
                  >
                    <div className="">{item.icon}</div>

                    <h3 style={{ margin: "1px", whiteSpace: "nowrap" }}>
                      {item.text}
                    </h3>
                  </div>
                  {index === 2 ? "" : <hr className="new1" />}{" "}
                </>
              ))}
            </div>

            {!isSubmitted ? (
              activeSection < 2 ? (
                <button
                  onClick={() => activeSectionChangeHandler("next")}
                  className={`${handleDisbaled() ? "disbaled-btn" : "enabled-btn"}`}
                >
                  Next {">"}{" "}
                </button>
              ) : (
                <button onClick={appendSpreadsheet} className={`${handleDisbaled() ? "disbaled-btn" : "enabled-btn"}`}>
                  Submit{" "}
                </button>
              )
            ) : null}
          </div>

          {!isSubmitted ? (
            <div>
              {activeSection === 2 && (
                <FinanceSection data={data} onChnageHandler={onChnageHandler} />
              )}
              {activeSection === 1 && (
                <VehicleDetailsSection
                  bodyType={bodyType}
                  payload={payload}
                  model={model}
                  yearList={yearList}
                  make={makeList}
                  data={data}
                  onChnageHandler={onChnageHandler}
                />
              )}
              {activeSection === 0 && (
                <PersonalDetailSection
                  data={data}
                  state={state}
                  district={district}
                  onChnageHandler={onChnageHandler}
                />
              )}
            </div>
          ) : (
            <div>{isSubmitted && <SubmitSection />}</div>
          )}
        </div>

        <div className="veh-section-2">
          <div className="content-container">
            <h3>GET IT FINANCED</h3>
            <p>
              Get behind the wheel of your dream <br /> truck with our{" "}
              <span style={{ color: "#F8B301", margin: "0px 10px" }}>
                {" "}
                hassle-free EMI options!{" "}
              </span>{" "}
            </p>
          </div>
          <div className="veh-btn-container">
            <button className="veh-know-more">
              <span>Know More </span>
            </button>
            <button className="veh-contact-us">
              <Contact /> <span>Contact Us</span>
            </button>
          </div>

        </div>

      </div>
    </div>
  );
};

export default VehicleRequirementComponent;
