import React, { useEffect, useRef, useState } from "react";
import ExploreTruckImg from "../assets/exploreTruckImg.png";
import rightArrow from "../assets/rightArrow.svg";
import { ExploreTruckItemLanding } from "../components/index";
import { useNavigate } from "react-router-dom";
import { get } from "../utils/functions";


const ExploreTruck = () => {
  const navigate = useNavigate();
  const [vehicles, setVehicles] = useState([]);
  const [height, setheight] = useState();

  useEffect(() => {
    (async () => {
      try {
        const resp = await get("/sell/new/random/vehicles", {}, {});
        if (resp.status_code === 200) setVehicles(resp.data);
      } catch (error) {}
    })();
  }, []);
  const handleView = () => {
    navigate("/second-hand-truck-for-sale");
  };
  return (

    <div className="explore-truck-sections">
      <div className="explore-truck-inner">
        <div className="explore-truck-heading">
          <h3>
            Explore <span>amazing</span> trucks!!
          </h3>
          <button className="view-btn" onClick={handleView}>
            View All <img src={rightArrow} alt="right arrow" />
          </button>
        </div>

        <div className="explore-truck-items">
          {vehicles?.slice(0, 3).map((vehicle) => {
            return (
              <ExploreTruckItemLanding
                key={vehicle.vehicle_uid}
                id={vehicle.vehicle_uid}
                height={height}
                setheight={setheight}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ExploreTruck;
