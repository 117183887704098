import styled from "styled-components";

const Wrapper = styled.div`
   button {
    width: 200px;
    height: 3rem;

    border: 1px solid #d4d4d4;
    /* display: flex; */
    border-radius: 0.8rem;
    color: #000;
    /* align-items: center;
    justify-content: center;
    font-size: 1.5rem; */
    padding: 1.5rem;
    cursor: pointer;
  }

   button:hover {
    translate: scale(1.1);
  }

  
`;

const CityItem = ({ CityName,value}) => {
  return (
    <Wrapper>
      <button  value={value}>{CityName}</button>
    </Wrapper>
  );
};

export default CityItem;
