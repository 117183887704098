import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    showSidebar: true,
  },
  reducers: {
    // onClickSellTab: (state) => {
    //   state.isSell = true;
    // },
    // onClickBuyTab: (state) => {
    //   state.isSell = false;
    // },

    toggleSidebar: (state) => {
      state.showSidebar = !state.showSidebar;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleSidebar } = adminSlice.actions;

export default adminSlice.reducer;
