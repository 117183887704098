import React from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "../assets/FacebookIcon.svg";
import InstagramIcon from "../assets/InstagramIcon.svg";
import TwitterIcon from "../assets/TwitterIcon.svg";
import AppleStoreImg from "../assets/AppleStoreImg.png";
import PlayStoreImg from "../assets/PlayStoreImg.png";
const Footer = ({ show }) => {
  return (
    <footer style={{margin:'0px'}}>
      <div className="footer-container">
        <div className="footer-column" style={{ flexDirection: "column" }}>
          <h3>OVERVIEW</h3>
          <ul style={{ flexDirection: "column",gap:'1px' }}>
            <li>
              <Link className="footer-links" to="/aboutus">
                About us
              </Link>
            </li>
            <li>
              <Link className="footer-links" to="#">
                Faqs
              </Link>
            </li>
            <li>
              <Link className="footer-links" to="/privacy-policy">
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/terms-and-conditions" className="footer-links">
                Terms & Conditions
              </Link>
            </li>
            {/*  <li>
              <Link className="footer-links" to="#">
                Corporate Policies
              </Link>
            </li>
            <li>
              <Link className="footer-links" to="#">
                Investers
              </Link>
          </li>*/}
            <li>
              <Link className="footer-links" to="/blogs">
                Blog
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-column" style={{ flexDirection: "column" }}>
          <h3>OTHERS</h3>
          <ul style={{ flexDirection: "column",gap:'1px' }}>
            <li>
              <Link className="footer-links" to="#">
                Become a Partner
              </Link>
            </li>
            <li>
              <Link className="footer-links" to="/transfer">
                RC Transfer Status
              </Link>
            </li>
            <li>
              <Link className="footer-links" to="#">
                Testimonials
              </Link>
            </li>
            <li>
              <Link className="footer-links" to="#">
                Customer Care
              </Link>
            </li>
            <li>
              <Link className="footer-links" to="/vehicle-requirements">
                Vehicle Requirement
              </Link>
            </li>
            <li>
              <Link className="footer-links" to="/emicalculator">
                EMI Calculator
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-column" style={{ flexDirection: "column" }}>
          <h3>CONNECT WITH US</h3>
          <ul style={{ flexDirection: "column",gap:'1px' }}>
            <li>
              <Link className="footer-links" to="#">
                +91 7021411346
              </Link>
            </li>
            <li>
              <Link className="footer-links" to="#">
                info@cavalo.in
              </Link>
            </li>
            <li>
              <Link className="footer-links" to="#">
                Contact Us
              </Link>
            </li>
            <li>
              <Link className="footer-links" to="#">
                For Businesses
              </Link>
            </li>
            <li>
              <Link className="footer-links" to="#">
                Feedback
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-column" style={{ flexDirection: "column" }}>
          <h3>GET OUR APP</h3>
          <div className="footer-btn">
            <img src={AppleStoreImg} alt="apple store " />
            <img src={PlayStoreImg} alt="play store " />
          </div>
          <div>
            <h6>Follow Us</h6>
            <div className="social-media-links">
              <Link
                className="footer-links"
                to="https://www.facebook.com/p/Cavalo-100077754160030/"
              >
                <img src={FacebookIcon} alt="facebook icon" />
              </Link>

              <Link
                className="footer-links"
                to="https://instagram.com/cavaloofficial?igshid=YmMyMTA2M2Y="
              >
                <img src={InstagramIcon} alt="instagram icon" />
              </Link>

              <Link
                className="footer-links"
                to="https://twitter.com/Cavalo_Official?t=gmdGh30Ka3Uxj6ipn9iAZw&s=08"
              >
                <img src={TwitterIcon} alt="twitter icon" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="copyright-inner">
          <p>
            The content of this site is copyright-protected and is the property
            of On Road Logisol Private Limited..
          </p>
          <p className="tata-digital">
            <span>CAVALO</span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
