import React, { useRef, useState } from 'react';
import './ExploreTruckHome.css'
import home_image1 from "../assets/home_image1.jpg";
import home_image2 from "../assets/home_image2.jpg";
import home_image3 from "../assets/home_image3.jpg";
import home_image4 from "../assets/home_image4.jpg";
import home_image5 from "../assets/home_image5.jpg";
import home_image6 from "../assets/home_image6.jpg";
import home_image7 from "../assets/home_image7.jpg";
import home_image8 from "../assets/home_image8.jpg";
import home_image9 from "../assets/home_image9.jpg";
import home_image10 from "../assets/home_image10.jpg";
import home_image11 from "../assets/home_image11.jpg";
import home_image12 from "../assets/home_image12.jpg";
import home_image13 from "../assets/home_image13.jpg";
import home_image14 from "../assets/home_image14.jpg";
import home_image15 from "../assets/home_image15.jpg";
import home_image16 from "../assets/home_image16.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


export default function ExploreTruckHome() {

    const [productArr, setProductArr] = useState([
        {
            images: [
                {
                    image: home_image1,
                },
                {
                    image: home_image2,
                },
                {
                    image: home_image3,
                },
                {
                    image: home_image4,
                },
            ],
            modal: 'TRUCK',
            fuel: 'DIESEL',
            product: '2021 1512g LPT Tata',
            weight: '16000',
            condition: '25% - 50%',
            price: '1600000',
        },
        {
            images: [
                {
                    image: home_image5,
                },
                {
                    image: home_image6,
                },
                {
                    image: home_image7,
                },
                {
                    image: home_image8,
                },
            ],
            modal: 'TRUCK',
            fuel: 'DIESEL',
            product: '2021 Signa 2821.T 5L Turbotronn Tata',
            weight: '28000',
            condition: '25% - 50%',
            price: '2000000',
        },
        {
            images: [
                {
                    image: home_image9,
                },
                {
                    image: home_image10,
                },
                {
                    image: home_image11,
                },
                {
                    image: home_image12,
                },
            ],
            modal: 'TRUCK',
            fuel: 'DIESEL',
            product: '2015 1109g LPT Tata',
            weight: '12990',
            condition: '25% - 50%',
            price: '650000',
        },
        {
            images: [
                {
                    image: home_image13,
                },
                {
                    image: home_image14,
                },
                {
                    image: home_image15,
                },
                {
                    image: home_image16,
                },
            ],
            modal: 'Bus',
            fuel: 'DIESEL',
            product: '2016 Staff Bus BharatBenz',
            weight: '0',
            condition: '50% - 75%',
            price: '1250000',
        },
    ])

    
    return (
        <>
            {
                productArr && productArr?.length > 0 && productArr?.map((el, i) => (

                    < div className="explore-truck-item1" key={i}>
                        <div className="explore-truck-img-1">
                            <Swiper
                                spaceBetween={30}
                                centeredSlides={true}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay]}
                                className="mySwiper1"

                            >
                                {
                                    el?.images && el?.images?.length > 0 && el?.images?.map((ele, ind) => (
                                        <SwiperSlide key={ind}>
                                            <img
                                                crossOrigin="anonymous"
                                                src={ele?.image}
                                                alt="explore truck "
                                            />
                                        </SwiperSlide>
                                    ))
                                }

                            </Swiper>

                            <div className="span">
                                <span>{el?.modal}</span>
                                <span>{el?.fuel}</span>
                            </div>
                            <div className="absolute"></div>
                        </div>

                        <div className="explore-truck-description">
                            <h3>{el?.product}</h3>
                            <div className="explore-truck-details">
                                {/* <span> {data?.odometer_reading} Km </span> |{" "} */}
                                <span> {el?.weight} </span> |<span> {el?.modal} </span>{" "}
                                | <span> {el?.condition} </span>
                            </div>
                            <div className="explore-truck-price">
                                <span>₹ {el?.price}</span>
                                {/* <span>₹{data?.price + 20000}</span> */}
                            </div>
                            <div>
                                <span className="inclusive">inclusive of all taxes</span>
                            </div>

                            <a href='#banner' className="home_btn" >Get a Quote</a>
                        </div>
                    </div >
                ))

            }


        </>
    )
}
