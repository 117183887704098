import styled from "styled-components";

const Wrapper = styled.div`
  margin: 0;
  width: fit-content;
  .happy-customers-item {
    /* padding: 2rem 2rem 1rem 2rem; */
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 18px;
    background: #fff;
    box-sizing: border-box;
    margin: 0;
    overflow: hidden;
    width: 338px;
    height: 508px;
    box-shadow: 0px 1.314232349395752px 23.65618324279785px 0px
      rgba(0, 0, 0, 0.18);
  }
  .happy-customers-item:hover {
    background-color: #f0f0f0;
    transform: scale(1.03);
    transition: all 0.4s ease-in-out;
  }

  .happy-customers-img {
    position: relative;
    display: flex;
    width: 338px;
    height: 223px;
    align-items: flex-start;
    border-radius: 12px 12px 0px 0px;
  }
  .happy-customers-img img {
    width: 100%;
  }

  .happy-customers-review {
    /* padding: 1.5rem; */
    box-sizing: border-box;
    height: 248px;
    /* width:  */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 290px;
    margin: 0 auto;
    /* text-align: center; */
  }

  .happy-customers-review p {
    color: #757575;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 1.5;
    margin: 0;
    /* text-align: justify; */
  }

  .happy-customers-details {
    color: #1e2130;
    font-size: 16px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.32px;
    /* padding: 1rem; */
  }
  .happy-customers-details span {
    margin: 0 0.5rem;
    letter-spacing: 0.1rem;
  }
  @media (max-width: 1600px) {
    .happy-customers-review p {
      font-size: 18px;
    }
  }
  @media (max-width: 767px) {
    .happy-customers-item {
      /* min-width:35rem; */
      width: 222.105px;
      height: 350px;
      border-radius: 11.828px;
      gap: 13.142px;
      padding-bottom: 13px;
    }
    .happy-customers-review p {
      color: #757575;
      font-size: 12px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.24px;
    }
    .happy-customers-review {
      display: flex;
      width: 190.564px;
      flex-direction: column;
    }
    .happy-customers-img {
      width: 222.105px;
      height: 146.537px;
      align-items: flex-start;
    }

    .happy-customers-details {
      font-size: 10.514px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.21px;
    }
  }
`;

const HappyCustomersItem = ({
  CustomerImage,
  CustomerReview,
  CustomerName,
  CustomerCity,
}) => {
  return (
    <Wrapper>
      <div className="happy-customers-item">
        <div className="happy-customers-img">
          <img src={CustomerImage} alt="Customer Image" />
        </div>
        <div className="happy-customers-review">
          <p>{CustomerReview}</p>
          <div className="happy-customers-details">
            <span> {CustomerName} </span> | <span> {CustomerCity} </span>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default HappyCustomersItem;
