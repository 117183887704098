import styled from "styled-components";

const Wrapper = styled.div`
  /* width: 100%; */
  .brand-item {
    /* width: 100%; */
    /* min-width: 212px; */
    width: 12vw;
    height: 13rem;
    border: 1px solid #d4d4d4;
    display: flex;
    border-radius: 0.8rem;
    align-items: center;
    justify-content: center;
    margin-right: 0;
  }

  .brand-item img {
    /* width: 10rem; */
  }

  @media (max-width: 767px) {
    .brand-item {
      min-width: 15rem;
      height: 80px;
      border-radius: 0.5rem;
      border: 1px solid #d4d4d4;
    }

    .brand-item img {
      width: 7rem;
    }
  }

  @media (max-width: 600px) {
    .brand-item {
      min-width: 10rem;
      height: 63px;

      border: 1px solid #d4d4d4;
    }

    .brand-item img {
      width: 6rem;
    }
  }

  @media (max-width: 390px) {
    .brand-item {
      min-width: 9rem;
      height: 7rem;
      border: 1px solid #d4d4d4;
    }

    .brand-item img {
      width: 5rem;
    }
  }
`;

const BrandItem = ({ BrandName }) => {
  return (
    <Wrapper>
      <div className="brand-item">
        <img src={BrandName} alt="power trac brand" />
      </div>
    </Wrapper>
  );
};

export default BrandItem;
