import { useNavigate, createSearchParams } from "react-router-dom";
import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import styled from "styled-components";
import ExploreTruckImg from "../assets/exploreTruckImg.png";
import { get } from "../utils/functions";
import { root_url } from "../utils/url.constant";
import { IoMdClose } from "react-icons/io";
import qs from "qs";
import { toast } from "react-hot-toast";
import axios from "axios";

const Wrapper = styled.div.attrs({
  className: "item-wrapper",
})`
  &.item-wrapper {
    margin: 2rem 0rem;
  }

  .explore-truck-item {
    display: flex;
    width: 338px;
    padding: 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex-shrink: 0;
    border-radius: 18px;
    background: #1e2130;
    box-sizing: border-box;
  }
  .explore-truck-item:hover {
    background-color: #1e2140;
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  }

  .explore-truck-img-1 {
    position: relative;
    display: flex;
    height: 223px;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;

    /* z-index: 100; */
    /* border: 1px solid white; */
    overflow: hidden;

    /* padding: 10px; */
  }
  .explore-truck-img-1 img {
    /* width: 97%;
    padding: 5px; */
    /* z-index: 0; */
    height: 223px;
    width: 100%;
    object-fit: cover;

    /* display:none; */
  }

  .span {
    position: absolute;
    /* left: 2vw; */
    /* width: 100%; */
    /* margin: 1rem auto; */
    width: auto !important;
    bottom: 0.5rem;
    margin: 0 0 12px 12px !important;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    z-index: 100;
  }
  .explore-truck-img-1 span {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(10px);
    padding: 6px 12px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.24px;
    text-transform: uppercase;
  }
  .explore-truck-img p {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 12.0325px;
    line-height: 15px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #ffffff;
  }

  .explore-truck-description {
    padding: 12px;
    box-sizing: border-box;
    width: 100%;
    /* margin-bottom:1.5rem; */
    /* text-align: center; */
  }

  .explore-truck-description h3 {
    color: #ffffff;
    font-size: 24px;
    font-family: Mulish;
    font-weight: 800;
    letter-spacing: 0.48px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .explore-truck-img div {
    position: absolute;
    /* left: 2vw; */
    width: 100%;
    margin: 1rem auto;
    bottom: 0.5rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    /* border: 1px solid white; */
  }
  .explore-truck-img span {
    margin: 0;
    font-size: 10px;
  }

  .explore-truck-details {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #b6bdde;
    color: #b6bdde;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    margin-top: 8px;
    margin-bottom: 18px;
    /* padding: 1rem; */
  }
  .explore-truck-details span {
    /* margin: 0 0.5rem; */
    letter-spacing: 0.1rem;
    font-size: 12px;
    font-family: Mulish;
    font-weight: 500;
  }
  .explore-truck-price {
    display: flex;
    align-items: flex-end;
    justify-content: start;
  }
  .explore-truck-price span:first-child {
    background: #f6af33;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 32px;
    margin: 0;
    margin-right: 1rem;
    font-weight: 800;
  }

  .explore-truck-price span:last-child {
    font-size: 1.5rem;
    color: #7d808e;
    text-decoration-line: line-through;
    margin-bottom: 0.6rem;
  }

  .inclusive {
    color: #7d808e;
    font-size: 150%;
    text-align: left;
    margin: 0;
    margin-right: 1rem;
  }

  .explore-truck-know-more {
    width: 100%;
    margin-top: 12px;
    box-sizing: border-box;
    background-color: #151720;
    border: 1px solid #151720;
    border-radius: 0.8rem;
    color: #ffffff;
    padding: 17px 27px;
    font-size: 16px;
    font-family: Mulish;
    font-weight: 700;
  }
  .explore-truck-know-more:hover {
    background-color: #151735;
    transform: scale(1.1);
    transition: all 0.1s ease-in 0.1s ease-out;
    /* box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); */
  }

  .home_btns{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-between;
  }

  .home_btns .home_btn {
    width: 100%;
    margin-top: 12px;
    box-sizing: border-box;
    background-color: #151720;
    border: 1px solid #151720;
    border-radius: 8px;
    color: #ffffff;
    padding: 13px 20px;
    font-size: 14px;
    font-family: Mulish;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
  }

  .home_btns .home_btn:hover {
    background-color: #151735;
    transform: scale(1.1);
    transition: all 0.1s ease-in 0.1s ease-out;
    /* box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); */
  }

  @media (max-width: 1200px) {
    .explore-truck-img span {
      padding: 3px 6px;
    }
  }

  @media (max-width: 767px) {
    .explore-truck-item {
      width: 288.44px;
      padding: 15.361px;
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      flex-shrink: 0;
    }
    .explore-truck-img-1 img {
      height: 190.302px;
      align-items: flex-start;
      align-self: stretch;
    }
    .explore-truck-img-1 {
      height: 190.302px;
    }
    .explore-truck-description {
      padding-bottom: 0;
    }
    .explore-truck-description h3 {
      whitespace: nowrap;
      font-size: 20.481px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 0.41px;
    }
    .explore-truck-details span {
      font-size: 10.24px !important;
      font-family: Mulish;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.205px;
    }
    .explore-truck-price span:first-child {
      font-size: 27.308px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 0.546px;
    }

    .explore-truck-price span:last-child {
      font-size: 15.361px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .inclusive {
      font-size: 10.24px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.205px;
    }
    .explore-truck-know-more {
      /* position: absolute; */
      font-size: 1.3rem;
      /* width: 257px; */
      padding: 14.507px 20.481px 14.507px 25.601px;
      margin-top: 9px;
    }
    /*  .explore-truck-img div {
      
    }
    .explore-truck-img span {
      font-size: 12px;
    }

    .explore-truck-description {
      padding: 0.5rem;
      box-sizing: border-box;
      text-align: center; 
    }

    

    .explore-truck-details {
      font-size: 130%;
    }

    

    .explore-truck-price span:last-child {
      font-size: 2rem;
    }

    .inclusive {
      font-size: 140%;
    }

    .explore-truck-know-more {
      font-size: 1.3rem;
      width: 100%;
      padding: 1rem 0;
    } */
  }
`;


const ExploreTruckItem = ({ id, height = null, setheight = null }) => {

  const navigate = useNavigate();
  const [stateArr, setStateArr] = useState([]);
  const [img, setimg] = useState();
  const [data, setData] = useState();
  const textAreaRef = useRef();

  const [closeBtn1, setCloseBtn1] = useState(false)
  const [number, setNumber] = useState("");

  const [state, setState] = useState("");
  const [email, setEmail] = useState("");
  const [district, setDistrict] = useState("");
  const [name, setName] = useState("");

  const appendSpreadsheet = async () => {
    if (name === "" || number === "" || state === "" || district === "") {
      toast.error("Fields cannot be empty");
      return;
    }

    try {
      let dataObj = qs.stringify({
        name,
        email,
        phone: number,
        state,
        city: district,
        message: window.location.href,
      });

      console.log(dataObj, "dataObjdataObjdataObj");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${root_url}/buy/contact`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: dataObj,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data?.status_code === 400) {
            toast.error(response?.data?.message);
          } else {
            toast.success("We Will Contact You Soon.");
            setCloseBtn1(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };


  useEffect(() => {
    (async () => {
      try {
        const resp = await get(`/buy/new/view/${id}`, {}, {});
        if (resp.status_code === 200) setData(resp.data);
      } catch (error) { }
    })();
  }, [id]);


  return (

    < Wrapper>
      {data && (
        <div className="explore-truck-item" ref={textAreaRef}>
          <div className="explore-truck-img-1">
            <img
              crossOrigin="anonymous"
              src={`${root_url}/${data?.image}`}
              alt="explore truck "
            />
            <div className="span">
              <span>{data?.vehicle_type}</span>
              <span>{data?.fuel}</span>
            </div>
            <div className="absolute"></div>
          </div>

          <div className="explore-truck-description">
            <h3>
              {data?.year} {data?.model} {data?.make}
            </h3>
            <div className="explore-truck-details">
              {/* <span> {data?.odometer_reading} Km </span> |{" "} */}
              <span> {data?.weight} </span> |<span> {data?.vehicle_type} </span>{" "}
              | <span> {data?.tyre_condition} </span>
            </div>
            <div className="explore-truck-price">
              <span>₹{data?.price}</span>
              {/* <span>₹{data?.price + 20000}</span> */}
            </div>
            <div>
              <span className="inclusive">inclusive of all taxes</span>
            </div>
            {(window.location.pathname !== "/explore" && window.location.pathname !== "/landing") && (
              <button className="explore-truck-know-more " onClick={() => {
                const searchParams = { vehicle_uid: data?.vehicle_uid };
                navigate({
                  pathname: `/en/${data?.vehicle_type === "N/A"
                    ? "used"
                    : data?.vehicle_type?.toLowerCase().replace(/ /g, "-")
                    }/${data?.make.toLowerCase().replace(/ /g, "-")}/${data?.year
                    }-${data?.model.toLowerCase().replace(/ /g, "-")}/${data?.vehicle_uid
                    }`,
                  // search: `?${createSearchParams(searchParams)}`,
                });
              }}>Know More</button>
            )}

            {
              window.location.pathname == "/landing" && (
                <div className="home_btns">
                  <button className="home_btn"
                    onClick={() => {
                      const searchParams = { vehicle_uid: data?.vehicle_uid };
                      navigate({
                        pathname: `/en/${data?.vehicle_type === "N/A"
                          ? "used"
                          : data?.vehicle_type?.toLowerCase().replace(/ /g, "-")
                          }/${data?.make.toLowerCase().replace(/ /g, "-")}/${data?.year
                          }-${data?.model.toLowerCase().replace(/ /g, "-")}/${data?.vehicle_uid
                          }`,
                        // search: `?${createSearchParams(searchParams)}`,
                      });
                    }}
                  >View Details/विवरण देखें</button>
                  <button className="home_btn" onClick={() => setCloseBtn1(!closeBtn1)}>Get a Quote</button>
                </div>
              )
            }

          </div>
        </div>
      )}

      {
        closeBtn1 && (
          <div className="main_modal">
            <div className="modal">
              <div className="icon" onClick={() => setCloseBtn1(!closeBtn1)}>
                <IoMdClose />
              </div>

              <div className="fields">
                <input
                  type="text"
                  placeholder="Name"
                  className="input_box"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Mobile No."
                  className="input_box"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />

                <input
                  type="email"
                  placeholder="Email"
                  className="input_box"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <select
                  name="state"
                  className="cusomtinput"
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                >
                  <option>Select State</option>
                  {stateArr?.length > 0
                    ? stateArr.map((item) => (
                      <option value={item.state_name}>{item.state_name}</option>
                    ))
                    : null}
                </select>
                <input
                  type="text"
                  className="input_box"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                  placeholder="District"
                />
              </div>
              <button
                className="btn_submit"
                type="button"
                onClick={appendSpreadsheet}
              >
                Submit
              </button>
            </div>
          </div>
        )
      }

    </Wrapper >
  );
};

export default ExploreTruckItem;
