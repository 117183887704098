import React, { useEffect, useState } from "react";
import ExploreTruckImg from "../assets/exploreTruckImg.png";
import rightArrow from "../assets/rightArrow.svg";
import { ExploreTruckItem } from "../components/index";
import { useNavigate } from "react-router-dom";
import { get } from "../utils/functions";
import { toast } from "react-hot-toast";
const ExploreTruck = ({ vehicles }) => {
  const navigate = useNavigate();
  const [vehiclesData, setVehicles] = useState([]);
  const fetchData = async (id) => {
    try {
      const resp = await get(`/buy/new/view/${id}`, {}, {});
      // console.log(resp)
      if (resp.status_code === 200) return resp.data;
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    const fetchDataForVehicles = async () => {
      try {
        const promises = vehicles.map((vehicle) =>
          fetchData(vehicle.vehicle_uid)
        );
        const data = await Promise.all(promises);
        setVehicles(data);
      } catch (error) {
        // Handle the error if needed
        // console.error(error);
      }
    };

    fetchDataForVehicles();
  }, [vehicles]);

  // const handleView = () => {
  // navigate("/explore");
  // };
  return (
    <div className="explore-truck-section" style={{ background: "#fff" }}>
      <div className="explore-truck-inner-prod">
        <div className="explore-truck-heading" style={{ margin: "0 auto" }}>
          <h3 style={{ margin: "5rem auto", color: "black" }}>
            Explore <span>Similar</span> trucks
          </h3>
        </div>

        <div className="explore-truck-items-prod">
          {vehiclesData?.map((vehicle) => {
            // console.log(vehicle,"vehicle")
            return (
              <ExploreTruckItem key={vehicle.vehicle_uid} data={vehicle} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ExploreTruck;
