import { createSlice } from "@reduxjs/toolkit";

export const landingSlice = createSlice({
  name: "landing",
  initialState: {
    isSell: true,
    isChecked: true,
  },
  reducers: {
    // onClickSellTab: (state) => {
    //   state.isSell = true;
    // },
    // onClickBuyTab: (state) => {
    //   state.isSell = false;
    // },
    checked: (state) => {
      state.isChecked = !state.isChecked;
      //state.isChecked);
    },
  },
});

// export function GetMakes() {
//   return async function GetMakesThunk(dispatch, getState) {
//     try {
//       const response = await axios.get(`${URL}/truck/make`, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });
//       const { data } = response.data;
//       // //data);
//       dispatch(sellSlice.actions.fetchMake(data));
//     } catch (err) {
//       //err);
//     }
//   };
// }

// Action creators are generated for each case reducer function
export const { BuyTab, SellTab, checked } = landingSlice.actions;

export default landingSlice.reducer;
