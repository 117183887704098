
import React from "react"
import moment from 'moment'
import {Link} from 'react-router-dom'
import "./BlogCardComponent.css"
import BlogImage from "../../../assets/blogImage.jpg"


const BlogCardComponent =({index,post,key})=>{
    console.log(post?.fields?.blogImage?.fields?.file.url.includes("http"))
    return  (
        <Link
        className="blog-link"
        to={`/blog-detail-page/${post?.fields?.urlSlug}/${post?.sys?.id}`}
        >
        <div className="card-detail-container"> 

        <div className="card-image-container">

        <img crossOrigin="anonymous" style={{width:'100%',height:'100%',objectFit:'contain'}} src={`${post?.fields?.blogImage?.fields?.file?.url}`}/>
        
        </div>
        <div className="card-info-container">

     { (post?.fields?.cavaloFieldTag || post?.fields?.cavaloIndustryTag ) &&    <div className="card-tag-container">
         {post?.fields?.cavaloFieldTag && <span className="card-tag">{post?.fields?.cavaloFieldTag?.toUpperCase()}</span>}
          {post?.fields?.cavaloIndustryTag && <span className="card-tag">{post?.fields?.cavaloIndustryTag?.toUpperCase()}</span>}
         </div>}
        
       
        <h2 className="card-heading">{post?.fields?.blogTitle}</h2>  

        <p className="card-text">{post?.fields?.blogDescription}</p>

         <span className="date-text">{moment(post?.fields?.blogDate).format("DD MMM YYYY")}
         </span>
        </div>
        
        </div>

        </Link>
    )



}




export default BlogCardComponent