import React, { useState, useEffect } from "react";
import { ReactComponent as Bus } from "../../assets/bus.svg";
import { ReactComponent as Truck } from "../../assets/TruckVeqSvg.svg";
import { ReactComponent as CloudUpload } from "../../assets/uploadIcon.svg";
import { Cancel } from "@mui/icons-material";
import "./VehicleDetailsSection.css";
import toast from "react-hot-toast";
import Select from "react-select";

const VehicleNewDetailsSection = ({
  data,
  onChnageHandler,
  bodyType,
  payload,
  cargoList,
  fuelList,
  tyreList,
  make,
  model,
}) => {
  const [vehiclePrice, setVehiclePrice] = useState(data?.price || "");
  const [totalKmDriven, setTotalKmDriven] = useState(data?.totalKmDriven || "");
  const [images, setImages] = useState(Array(4).fill(null));
  const [imagePreviews, setImagePreviews] = useState(Array(4).fill(null));
  const [RcNumber, setRcNumber] = useState(data?.RcNumber || "");
  const [weight, setWeight] = useState(data?.vehicleWeight || "");
  const [modelSelect, setModelSelect] = useState();

  useEffect(() => {
    const previews = images.map((image) =>
      image ? URL.createObjectURL(image) : null
    );
    setImagePreviews(previews);
    return () => {
      previews.forEach((preview) => preview && URL.revokeObjectURL(preview));
    };
  }, [images]);

  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid #526b67 !important",
      boxShadow: "0 !important",
      width: "55rem",
      zindex: "3",
      "&:hover": {
        border: "2px solid #D9D9D9 !important",
      },
    }),
  };

  const handleImageUpload = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      if (event.target.files[0].size > 5 * 1000 * 1024) {
        toast.error("File with maximum size of 5MB is allowed");
        return false;
      }

      const newImages = [...images];
      newImages[index] = file;
      setImages(newImages);

      console.log(newImages, "dsfdsfssdsdsd");
      onChnageHandler({
        target: {
          name: "images",
          value: newImages,
        },
      });
    }
  };

  const handlePriceChange = (e) => {
    const price = e.target.value;
    setVehiclePrice(price);
    onChnageHandler({
      target: {
        name: "price",
        value: price,
      },
    });
  };

  const handleKmDriven = (e) => {
    const totalKm = e.target.value;
    setTotalKmDriven(totalKm);
    onChnageHandler({
      target: {
        name: "totalKm",
        value: totalKm,
      },
    });
  };

  const handleRcNumber = (e) => {
    const RcNumber = e.target.value.toUpperCase();
    setRcNumber(RcNumber);
    onChnageHandler({
      target: {
        name: "RcNumber",
        value: RcNumber,
      },
    });
  };

  const handleCancelImage = (index) => {
    const newImages = [...images];
    newImages[index] = null;
    setImages(newImages);
  };

  return (
    <div className="personal-detail-main-box">
      <h2>Lets Choose Your Dream Ride</h2>

      <div className="vehicle-detail-fields-box">
        <div className="field-icon-container make-grid-item">
          <select
            name="make"
            onChange={onChnageHandler}
            value={data?.make?.brand_id}
          >
            <option>Select Make </option>
            {make?.length > 0
              ? make.map((item) => (
                  <option key={item.brand_id} value={item.brand_id}>
                    {item.brand_name}
                  </option>
                ))
              : null}
          </select>
        </div>

        <div className="field-icon-container model-grid-item">
          <Select
            styles={customStyles}
            options={
              Object.values(model)?.length > 0
                ? Object.values(model)
                    .flatMap((item) =>
                      item.variants.map((variant) => ({
                        model_id: item.model.model_id,
                        model_name: item.model.model_name,
                        variant_id: variant.variant_id,
                        variant_name: variant.variant_name,
                      }))
                    )
                    .map((combined) => ({
                      label:
                        combined.model_name + " - " + combined.variant_name,
                      value: combined.variant_id,
                    }))
                : []
            }
            value={modelSelect}
            onChange={(val) => {
              setModelSelect(val);
              onChnageHandler({
                target: {
                  name: "model",
                  value: val?.value,
                },
              });
            }}
          />

          {/* <select
            name="model"
            onChange={onChnageHandler}
            value={data?.model_id}
          >
            <option>Select Model </option>
            {Object.values(model)?.length > 0
              ? Object.values(model)
                  .flatMap((item) =>
                    item.variants.map((variant) => ({
                      model_id: item.model.model_id,
                      model_name: item.model.model_name,
                      variant_id: variant.variant_id,
                      variant_name: variant.variant_name,
                    }))
                  )
                  .map((combined) => (
                    <option
                      key={combined.variant_id}
                      value={combined.variant_id}
                    >
                      {combined.model_name} - {combined.variant_name}
                    </option>
                  ))
              : null}
          </select> */}
        </div>

        <div className="field-icon-container purchasedYear-grid-item">
          <select
            name="purchasedYear"
            onChange={onChnageHandler}
            value={data?.purchasedYear?.id}
          >
            <option>Year of Purchase</option>
            {[...Array(25)].map((_, index) => {
              const year = 2024 - index;
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              );
            })}
          </select>
        </div>

        <div className="field-icon-container bodyType-grid-item">
          <select
            name="bodyType"
            onChange={onChnageHandler}
            value={data?.bodyType?.id}
          >
            <option>Body Type</option>
            {bodyType.map((body, index) => {
              return (
                <option key={body.id} value={body.id}>
                  {body.body_type}
                </option>
              );
            })}
          </select>
        </div>

        <div className="field-icon-container payload-grid-item">
          <select
            name="payload"
            onChange={onChnageHandler}
            value={data?.payload?.id}
          >
            <option>Payload </option>
            {payload.map((body, index) => {
              return (
                <option key={body.id} value={body.id}>
                  {body.min_payload} - {body.max_payload}
                </option>
              );
            })}
          </select>
        </div>

        <div className="field-icon-container cargoList-grid-item">
          <select
            name="cargoLength"
            onChange={onChnageHandler}
            value={data?.cargoLength?.id}
          >
            <option>Cargo Length</option>
            {cargoList.map((body, index) => {
              return (
                <option key={body.id} value={body.id}>
                  {body.min_cargo_length} - {body.max_cargo_length}
                </option>
              );
            })}
          </select>
        </div>

        <div className="field-icon-container fuelList-grid-item">
          <select name="fuel" onChange={onChnageHandler} value={data?.fuel?.id}>
            <option>Fuel</option>
            {fuelList.map((body, index) => {
              return (
                <option key={body.id} value={body.id}>
                  {body.fuel}
                </option>
              );
            })}
          </select>
        </div>

        <div className="field-icon-container tyreList-grid-item">
          <select
            name="tyreCondition"
            onChange={onChnageHandler}
            value={data?.tyreCondition?.id}
          >
            <option>Tyre Conditions</option>
            {tyreList.map((body, index) => {
              return (
                <option key={body.id} value={body.id}>
                  {body.tyre_conditions} {body.tag}
                </option>
              );
            })}
          </select>
        </div>

        <div className="field-icon-container price-grid-item">
          <input
            type="number"
            name="price"
            placeholder="Vehicle Price"
            value={vehiclePrice}
            onChange={handlePriceChange}
          />
        </div>

        <div className="field-icon-container totalKm-grid-item">
          <input
            type="number"
            name="totalKmDriven"
            placeholder="Total Km Driven"
            value={totalKmDriven}
            onChange={handleKmDriven}
          />
        </div>
        <div className="field-icon-container rc-grid-item">
          <input
            type="text"
            name="RcNumber"
            placeholder="MH 02 BJ 9876"
            value={RcNumber}
            onChange={handleRcNumber}
          />
        </div>
        <div className="wight-grid-item">
          {/* <input
            type="text"
            name="vehicleWeight"
            placeholder="Vehicle Weight"
            value={weight}
            onChange={(e) => {
              setWeight(e.target.value);
              onChnageHandler({
                target: {
                  name: "vehicleWeight",
                  value: e.target.value,
                },
              });
            }}
          /> */}
        </div>
      </div>

      <div className="imageUploadConatiner">
        <p>Upload Images (Maximum 4)</p>
        <div className="image-upload-row">
          {[0, 1, 2, 3].map((index) => (
            <div key={index} className="image-upload-item">
              {imagePreviews[index] ? (
                <div className="image-preview-wrapper">
                  <img
                    src={imagePreviews[index]}
                    alt={`Uploaded ${index}`}
                    className="uploaded-image"
                  />
                  <Cancel
                    className="cancel-icon"
                    onClick={() => handleCancelImage(index)}
                  />
                </div>
              ) : null}
              {!imagePreviews[index] && (
                <label
                  htmlFor={`file-upload-${index}`}
                  className="upload-icon-wrapper"
                >
                  <CloudUpload className="upload-icon" />
                  <span className="upload-text">Click to upload</span>
                  <span className="or-text">or drag and drop</span>
                </label>
              )}
              <input
                id={`file-upload-${index}`}
                type="file"
                accept="image/*"
                onChange={(event) => handleImageUpload(index, event)}
                style={{ position: "absolute", left: "-9999px" }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VehicleNewDetailsSection;
