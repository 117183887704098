import React from "react";
import CavaloTruck from "../../assets/dessert.jpeg";
import DesertTruck from "../../assets/desertTruck.png";

import styled from "styled-components";
import naveen from "../../assets/naveen.jpeg";

import nisha from "../../assets/nisha.jpg";
import rutvik from "../../assets/rutvik.jpg";
import rupali from "../../assets/rupali.jpeg";
import rasika from "../../assets/rasika.jpeg";
import pramod from "../../assets/pramod.jpeg";
import divyam from "../../assets/Divyam.jpeg";

import "./AboutUsComponent.css";
import ProfileInfoCard from "./ProfileInfoCard";
import InfoCard from "./InfoCard";

const AboutusComponent = () => {
  const teamProfile = [
    {
      name: "Ruthvik",
      designation: "Procurement Head",
      info: `As a key team member, Ruthvik contributes expertise and
       passion to Cavalo. With a focus on customer needs and
       market trends, he plays a pivotal role in shaping the
       company's reputation for top-tier truck sales and service.`,
      image: rutvik,
    },
    {
      name: "Rasika",
      designation: "Sales",
      info: `Rasika brings a blend of creativity and efficiency to
      the Cavalo team. Her commitment to excellence in
      customer relations and marketing strategies enhances the
      brand, establishing Cavalo as a customer-centric and
      market-savvy truck provider.`,
      image: rasika,
    },
    {
      name: "Rupali",
      designation: "Sales Head",
      info: `With a meticulous eye for detail, Rupali ensures
      operational excellence at Cavalo. Her dedication to
      streamlining processes and maintaining quality standards
      makes her an integral part of the team, contributing to
      the company's success.`,
      image: rupali,
    },
    {
      name: "Nisha",
      designation: "Finance",
      info: `Nisha's expertise in finance and administration is
      instrumental in Cavalo's financial stability. Her
      meticulous financial management ensures the company's
      fiscal health, supporting its growth and sustainability
      in the competitive truck market.`,
      image: nisha,
    },
  ];

  const mentorProfile = [
    // {
    //   name: "Naveen Jain",
    //   designation: "Co-founder and CEO",
    //   info: `A visionary trailblazer, Naveen Jain is the driving force
    //   behind Cavalo, bringing unparalleled leadership and
    //   innovation. With a keen business acumen, he charts the
    //   course for success, inspiring the team to reach new
    //   heights in the truck industry.`,
    //   image: naveen,
    // },
    {
      name: "Divyam Agarwal",
      designation: "Co-founder",
      info: `A dynamic force in the truck industry, Divyam Agarwal's
     forward-thinking approach and dedication elevate Cavalo's
     standards. His leadership ensures the company's continued
     success, fostering innovation and customer satisfaction.`,
      image: divyam,
    },
    {
      name: "Pramod Agarwal",
      designation: "Co-founder",
      info: `A seasoned entrepreneur, Pramod Agarwal's strategic
     prowess fuels Cavalo's growth. His unwavering commitment
     to excellence and vast industry knowledge positions the
     company as a frontrunner, delivering quality trucks and
     unmatched service.`,
      image: pramod,
    },
  ];

  return (
    <div className=" ps-4 pe-4" id="aboutuscomponent">
      <div className="col-lg-12 col-md-12 col-sm-12">
        {/* Fist Section*/}
        <div className="section1">
          <img src={CavaloTruck} alt="img" className="aboutus-img" />
          <div className="aboutUs">
            <div
              className="main_heading heading"
              style={{ marginBottom: "20px" }}
            >
              About <span style={{ color: "#F8B301" }}>Us</span>
            </div>
            <div className="aboutUsDescription">
              Welcome to Cavalo, the online platform for buying and selling
              refurbished trucks. We are a team of passionate truck enthusiasts
              who are committed to making the process of buying and selling
              trucks as seamless as possible. With Cavalo, you can easily find
              the perfect refurbished truck for your business or sell your own
              truck at a fair price. Our platform is designed to be
              user-friendly and intuitive, with a wide range of filters and
              search options to help you find the perfect truck. Whether you're
              a seasoned truck owner or new to the market, Cavalo is the perfect
              place to buy or sell your refurbished truck. So why wait? Start
              your journey with Cavalo today!
            </div>
          </div>
        </div>

        {/*Second Section */}
        <div
          style={{
            backgroundColor: "#1E2130",
          }}
        >
          <div className="section-container">
            <div className="content-card-container">
              <div className="sub-content-card-container1">
                <div className="content-card">
                  <div className="">
                    <div className="heading"></div>
                    <span
                      className="sub_heading1 heading"
                      style={{ color: "#F8B301" }}
                    >
                      Still Wondering
                    </span>{" "}
                    <span
                      className="sub_heading1 heading"
                      style={{ color: "#fff" }}
                    >
                      about buying trucks from us?
                    </span>
                  </div>

                  <div className="contentDescription  margin-top-20">
                    At Cavalo, we make it easy for you to find and buy the
                    perfect refurbished truck for your needs. With a wide range
                    of filters and search options, you can easily find the truck
                    that meets your specific needs and budget. Plus, our team of
                    experts is always on hand to answer any questions you may
                    have and guide you through the buying process. We are
                    committed to providing a transparent and hassle-free
                    experience for our customers, so you can trust that you are
                    making an informed decision when you buy a truck with us.
                    Our large catalogue of trucks has every make and model you
                    want, with a thorough vetting process ensuring full
                    transparency in quality. With easy financing options, RC
                    Transfer assistance and a 6 month warranty on each vehicle,
                    we take all the uncertainty out of the purchase process. You
                    focus on how will you use your newly purchased vehicle comes
                    home, we’ll take care of the rest.
                  </div>
                </div>
              </div>
              <div className="sub-content-card-container2">
                <div className="content-card">
                  <div className="heading">
                    <span
                      className=" sub_heading1 heading"
                      style={{ color: "#F8B301" }}
                    >
                      Selling is no more a hassle,
                    </span>{" "}
                    <span
                      className="sub_heading1 heading"
                      style={{ color: "#fff" }}
                    >
                      we’ve got an edge!
                    </span>
                  </div>

                  <div className="contentDescription margin-top-20">
                    If you're looking to sell your truck, Cavalo is the perfect
                    platform for you. We make it easy to list your truck and
                    reach a wide audience of potential buyers. Plus, our team of
                    experts will work with you to determine the fair market
                    value of your truck, so you can trust that you are getting a
                    fair price. We are committed to providing a transparent and
                    hassle-free experience for our sellers, so you can trust
                    that you are in good hands when you sell your truck with us.
                  </div>
                  <div
                    className="contentDescription"
                    style={{ marginTop: "10px" }}
                  >
                    You need not wait for several months for RC transfer, we’ve
                    got you covered.
                  </div>
                </div>

                <div className="content-card margin-top-20">
                  <div className="">
                    <span
                      className=" sub_heading1 heading"
                      style={{ color: "#fff" }}
                    >
                      The{" "}
                    </span>
                    <span
                      className=" sub_heading1 heading"
                      style={{ color: "#F8B301" }}
                    >
                      most trusted destination for
                    </span>{" "}
                    <span
                      className=" sub_heading1 heading"
                      style={{ color: "#fff" }}
                    >
                      for refurbished trucks!
                    </span>
                  </div>

                  <div className="contentDescription  margin-top-20">
                    At Cavalo, we strive to be the most trusted destination for
                    refurbished trucks in India. We are committed to providing a
                    reliable, transparent, and convenient platform for buying
                    and selling refurbished trucks. Our team of experts
                    thoroughly inspects each truck to ensure that it meets the
                    highest standards of quality, and we offer a wide range of
                    filters and search options to help you find the perfect
                    truck. With Cavalo, you can trust that you are getting a
                    refurbished truck that you can rely on.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Third Section*/}
        <div
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div className="section-container">
            <div className="main_heading heading title-container">
              Our{" "}
              <span style={{ color: "#F8B301", margin: "0px 10px" }}>Team</span>
            </div>
            <div className="teamSection">
              {teamProfile.map((item, index) => (
                <ProfileInfoCard item={item} />
              ))}
            </div>
          </div>
        </div>

        {/* Fourth Section*/}
        <div
          style={{
            backgroundColor: "#1E2130",
          }}
        >
          <div className="section-container">
            <div className="our_mission">
              <div style={{ flex: 2 }}>
                <div className="heading">
                  <span className="main_heading" style={{ color: "#F8B301" }}>
                    Our
                  </span>{" "}
                  <span className="main_heading" style={{ color: "#fff" }}>
                    Mission{" "}
                  </span>
                </div>
              </div>

              <div className="our-mission-text" style={{ flex: 8 }}>
                At Cavalo, our mission is to provide a reliable and convenient
                platform for buying and selling refurbished trucks in India. We
                are dedicated to reaching a wide audience of truck buyers and
                sellers, and to making our platform accessible to people in
                every corner of the country. We are committed to providing a
                transparent and hassle-free experience for our customers, and to
                helping them find the perfect truck or get a fair price for
                their own. We believe that every business, no matter its size or
                location, deserves to have access to a high-quality refurbished
                truck, and we are dedicated to making that a reality.
              </div>
            </div>
          </div>
        </div>

        {/* Fifth Section*/}

        <div
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div className="section-container">
            <div className="main_heading heading title-container">
              Founder{" "}
              <span style={{ color: "#F8B301", margin: "0px 10px" }}> & </span>{" "}
              Mentor
            </div>

            <div className="mentorSection">
              {mentorProfile.map((item, index) => (
                <ProfileInfoCard item={item} />
              ))}
            </div>
          </div>
        </div>

        {/* sixth Section */}

        <div
          style={{
            backgroundColor: "#1E2130",
          }}
        >
          <div className="section1">
            <img
              src={DesertTruck}
              alt="desert-truck"
              className="our-vision-img"
            />
            <div className="desertSection">
              <div className="heading main_heading">
                <span className="main_heading" style={{ color: "#F8B301" }}>
                  Our
                </span>{" "}
                <span className="main_heading" style={{ color: "#fff" }}>
                  Vision{" "}
                </span>
              </div>
              <div className="ourVisionDescription">
                Now that you know what we do, Learn about the values that drive
                us and makes our customers fall in love with our services.
              </div>
            </div>
          </div>
        </div>

        {/* seventh Section*/}
        <div
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div className="section-container">
            <div className="teamSection">
              {["1", "2", "3", "4"].map((item) => (
                <InfoCard number={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutusComponent;
