import React from "react";
import styled from "styled-components";

// const ServicesItemWrapper = styled.div`
//   .div {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     border: 2px solid rgba(125, 128, 142, 0.15);
//     padding: 38px 28px;
//     border-radius: 8px;
//   }
// `;

const Wrapper = styled.div.attrs({
  className: "services",
})`
  &.services {
    text-align: center;
    border-radius: 8px;
    border: 2px solid rgba(125, 128, 142, 0.15);
    background: #1e2130;
    display: flex;
    padding: 38px 28px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    box-sizing: border-box;
    width: 98.5%;
    transition: transform 0.3s ease;
  }
  &.services:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    background: #2e3455;
  }
  &.services h4 {
    display: flex;
    width: 190px;
    flex-direction: column;
    color: #ffffff;
    font-size: 3rem;
    margin: 0;
    font-size: 26px;
    font-family: Mulish;
    font-weight: 700;
    text-align: center;
  }
  &.services p {
    display: flex;
    width: 265px;
    flex-direction: column;
    color: #b6bdde;
    text-align: center;
    font-size: 18px;
    font-family: Mulish;
    letter-spacing: 0.36px;
    margin: 0;
    box-sizing: border-box;
  }

  &.services div {
    transition: background 0.5s ease, border-color 0.5s ease,
      border-radius 0.5s ease;
    /* height: 300px; */
    /* margin-bottom: 60px; */
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  @media (max-width: 1000px) {
    &.services {
      width: 25rem;
      text-align: center;
    }
    &.services h4 {
      font-size: 2.3rem;
    }

    &.services p {
      font-size: 15px;
    }
  }

  @media (max-width: 767px) {
    &.services {
      /* width: 28rem; */
      padding: 28.615px 21.085px;
      box-sizing: border-box;
    }
    .content,
    .content p {
      width: 199.553px;
    }

    /* 
    &.services h4 {
      font-size: 1.5rem;
    } */

    &.services p {
      font-size: 14px;
    }
  }
`;

const ServicesItem = ({ Icon, title, description }) => {
  return (
    <Wrapper>
      <img src={Icon} alt="money icon" />
      <div className="content">
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    </Wrapper>
  );
};

export default ServicesItem;
