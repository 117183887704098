import React, { useState, useRef } from "react";
import styled from "styled-components";
import "./LoginPopup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEnvelope,
  faGreaterThan,
  faLock,
  faMobile,
  faMobileScreen,
  faSearch,
  faUnlockKeyhole,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { get } from "../utils/functions";
import Lock from "../assets/Lock.svg";
import Phone from "../assets/Phone.svg";
import { toast } from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import useOnClickOutside from "../utils/useOnClickOutside";

function LoginPopup(props) {
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isSend, setisSend] = useState(false);
  const [login, setlogin] = useState(false);
  const [selectLogin, setSelectLogin] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => props.onClose(false));

  function handlePasswordChange(event) {
    setOtp(event.target.value);
  }

  const onSendOtp = async (event) => {
    event.preventDefault();
    // //number)

    try {
      const resp = await get("/user/sendotp", { mobile_no: number });
      if (resp?.status_code === 200) {
        setisSend(true);
        toast.success("otp sent successfully");
        window.scrollTo(0, 0);
      } else {
        toast.error(resp?.message);
        setisSend(false);
      }
      setOtpLoading(false);
    } catch (error) {
      setOtpLoading(false);

      if (error?.response?.data?.message === "Unauthorized") {
        localStorage.clear();

        toast.error("Login to continue");
      } else if (error?.response?.data?.message === "Token expired") {
        localStorage.clear();
        toast.error("Login to continue");
      } else toast.error(error?.message);
    }
  };

  const ValidateOtp = async (event) => {
    event.preventDefault();
    try {
      const resp = await get("/user/login", { mobile_no: number, otp: otp });
      // //resp,"login");
      // console.log(resp);

      if (resp?.data !== "") {
        const { token, refresh_token } = resp.data;
        const date = new Date();
        const dateString = date.toISOString();
        localStorage.setItem("token", token);
        localStorage.setItem("refresh_token", refresh_token);
        localStorage.setItem("token_date", dateString);
        localStorage.setItem("account", JSON.stringify(resp.data));
        // window.location.reload(true);

        toast.success(resp?.message);
      }
      if (resp.status_code === 200) {
        // alert(resp?.message)
        props.onClose();
        // return <Toaster position="top-center" reverseOrder={false} />;
        // setlogin(true)
      } else {
        toast.error(resp?.message);
        // alert(resp?.message);

        // props.onClose();
      }
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);

      if (error?.response?.data?.message === "Unauthorized") {
        localStorage.clear();

        toast.error("Login to continue");
      } else if (error?.response?.data?.message === "Token expired") {
        localStorage.clear();

        toast.error("Login to continue");
      } else toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div className="login-popup">
      <div ref={ref} className="login-popup-inner">
        <div>
          <ul className="tab-group">
            <li className="tab active">
              <button>Login</button>
            </li>
            <li className="tab">
              <button
                onClick={() => {
                  props.onRegister();
                  props.onClose();
                }}
              >
                Register
              </button>
            </li>
          </ul>
        </div>
        <div className="login-popup-inner-inner">
          <div className="loginBar">
            <h1>Login Via OTP</h1>
            <button className="close-btn" onClick={props.onClose}>
              <FontAwesomeIcon icon={faXmark} className="cross" />
            </button>
          </div>
          {login ? (
            <h1>Login Successfull</h1>
          ) : (
            <form>
              <label>
                <div className="input-container">
                  <img src={Phone} className="mobile-icon" />
                  <input
                    type="text"
                    value={number}
                    className="phone-input"
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder="Phone No."
                  />
                  {otpLoading ? (
                    <CircularProgress
                      color="inherit"
                      style={{ color: "#1E2130" }}
                      size={25}
                    />
                  ) : (
                    <button
                      onClick={(e) => {
                        setOtpLoading(true);
                        onSendOtp(e);
                      }}
                    >
                      GET OTP
                    </button>
                  )}
                </div>
              </label>

              <label>
                <div className="input-container" style={{}}>
                  <img src={Lock} className="mobile-icon" />
                  <input
                    type="text"
                    value={otp}
                    className="phone-input"
                    onChange={handlePasswordChange}
                    placeholder="OTP"
                  />
                </div>
              </label>

              {isSend ? (
                <button
                  type="submit"
                  onClick={(e) => {
                    ValidateOtp(e);
                    setButtonLoading(true);
                  }}
                  disabled={buttonLoading}
                >
                  {buttonLoading ? (
                    <CircularProgress
                      color="inherit"
                      style={{ color: "#1E2130" }}
                      size={25}
                    />
                  ) : (
                    "Login   "
                  )}
                  {!buttonLoading && <FontAwesomeIcon icon={faArrowRight} />}
                </button>
              ) : (
                <button
                  type="submit"
                  style={{ opacity: "0.5", cursor: "not-allowed" }}
                >
                  Login &nbsp; &nbsp;
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              )}
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginPopup;
