import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { root_url } from "../../utils/url.constant";

const URL = root_url;

export const navSlice = createSlice({
  name: "nav",
  initialState: {
    rtoStates: [],
  },
  reducers: {
    setRtoStates: (state, action) => {
      // //action.payload,"rto");
      state.rtoStates = action.payload;
    },
  },
});

export function GetRtoStates() {
  // //"rto","kind")
  return async function GetRtoStatesThunk(dispatch, getState) {
    try {
      const response = await axios.get(`${URL}/general/rto/state`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = response.data;
      // //data);
      dispatch(navSlice.actions.setRtoStates(data));
    } catch (err) {
      //err);
    }
  };
}

// Action creators are generated for each case reducer function
export const { } = navSlice.actions;

export default navSlice.reducer;
