import styled from "styled-components";
import { useEffect, useState } from "react";
import yellowTruck from "../assets/bgyellowTruck.png";
import CartIcon from "../assets/FrameCart.svg";
import CartLightIcon from "../assets/FrameCartLight.svg";
import experimentArrowNext from "../assets/Next.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import SellIcon from "../assets/SellIcon.svg";
import SearchIcon from "../assets/VectorsearchIcon.svg";
import SearchBlackIcon from "../assets/SearchBlack.svg";
import { useDispatch, useSelector } from "react-redux";
import { BuyTab, SellTab, checked } from "../features/landing/landingSlice";
import { useNavigate, createSearchParams, Link } from "react-router-dom";
import {
  payloadRangeChange,
  rangeChange,
} from "../features/explore/exploreSlice";
import Banner from "./Banner";
import { toast } from "react-hot-toast";
import {
  GetMakes,
  GetYearByMake,
  GetModelByMakeAndYear,
  GetVariantByMakeAndYear,
  // GetVariantByModelFuelTransmission,
  setMakeId,
  setYearId,
  setRtoId,
} from "../features/sell/sellSlice";
import { GetRtoStates } from "../features/nav/navSlice";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { CircularProgress } from "@mui/material";

const Wrapper = styled.div`
  position: relative;
  width: 90%;
  margin: 0 auto;

  .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
    border-color: transparent !important;
    font-family: "Mulish" !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #979797 !important;
  }
  .Mui-focused {
    color: #616161 !important;
    border-color: #979797 !important;
  }
  .MuiSelect-select,
  .MuiInputLabel-root {
    font-family: "Mulish" !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    color: #757575 !important;

    /* padding-left: 18px; */
  }

  .MuiInputLabel-shrink {
    display: none;
  }

  .banner-wrapper-inner {
    display: grid;
    grid-template-columns: 2fr 1fr;
    height: 60vh;
    min-height: 58rem;
  }
  .banner-text-slider-btn {
    display: flex;
    margin-top: 5rem;
  }
  .text-slider-hr {
    width: 10rem;
    background: #c7c7c7;
    border-radius: 3rem;
    height: 0.4rem;
    margin-right: 1rem;
    transition: width 0.5s ease, background 0.5s ease;
  }

  .active {
    width: 13rem !important;
    background: #7a7a7a !important;
  }

  .content {
    display: none;
  }

  .activeContent {
    display: block;
  }

  .banner-heading {
  }
  .banner-heading h2 {
    font-size: 80px;
    font-weight: 800;
    margin-top: 1rem;
    margin-bottom: 0;
    margin-top: 4rem;
  }

  .banner-heading p {
    font-size: 3.3rem;
    color: #616161;
  }

  .banner-heading h2 span {
    background: #f6af33;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .banner-img {
    display: flex;
    align-items: center;
  }

  .banner-img img {
    position: absolute;
    z-index: -1;
    width: 60%;
    right: -10rem;
    bottom: 0;
  }
  /* sell and buy truck */

  .tabs-container {
    bottom: 0;
    width: 100%;
    background: transparent;
    height: 16rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-areas:
      "buytab selltab . . "
      "showitem  showitem showitem showitem";
    position: absolute;
  }

  .tabs-input {
    display: none;
  }

  .tabs .tab {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-top-right-radius: 0.8rem;
    border-top-left-radius: 0.8rem;

    cursor: pointer;
    margin-right: 2rem;
  }

  .item_buy,
  .item_sell {
    will-change: transform;
    transition: transform 0.3s ease-in-out;
  }

  .item_sell {
    /* background: yellow; */
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 1.5fr 0.8fr;
    grid-gap: 1rem;
  }
  .item_buy {
    /* background: yellow; */
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 1.5fr 0.8fr;
    grid-gap: 1rem;
  }

  .tabs {
    width: 40rem;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    position: relative;
    height: 6.5rem;
    position: absolute;
    bottom: 7.5rem;
  }

  .item {
    position: absolute;
    z-index:1;
    flex-wrap: wrap;
    box-shadow: rgb(0 0 0 / 30%) 0px 10px 12px;
    background-color: #ffffff;
    border-radius: 0.8rem;
    border-top-left-radius: 0;
    padding: 2rem;

    box-sizing: border-box;
  }
  .sub-item-buy select {
    padding: 0 20px;
    font-size: 16px;
    border-radius: 8px;
    background-color: #f5f5f5;
    color: #333;
  }
  .sub-item-buy p {
    color: #1e2130 !important;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .sub-item-buy option {
    font-size: 16px;
    padding: 10px;
    margin: 20px;
    background-color: #f5f5f5;
  }

  .tabs .tab {
    flex: 1 1 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
  }

  .tabs .tab img {
    margin-right: 1rem;
  }

  .tab {
    text-align: center;
    background: #1e2130;
    color: #bfc1ca;
  }

  #buyTab:checked ~ .item_buy {
    transform: translateX(0);
    grid-area: showitem;
  }
  #buyTab:checked ~ .item_sell {
    transform: translateX(100%);
    display: none;
  }
  #buyTab:checked ~ .tabs .tab_buy {
    background: #ffffff;
    color: #1e2130;
    /* z-index: 1; */
  }

  #sellTab:checked ~ .item_buy {
    transform: translateX(-100%);
    display: none;
  }
  #sellTab:checked ~ .item_sell {
    transform: translateX(0);
    grid-area: showitem;
  }
  #sellTab:checked ~ .tabs .tab_sell {
    background: #ffffff;
    color: #1e2130;
    /* z-index: 1; */
  }
  #text-display {
    margin-top: -40px;
  }

  .sub-item-buy {
    min-width: 16rem;
    width: 100%;
    padding: 1rem;
    height: 5.5rem;
    background-color: #f7f7f7;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
  }

  .sub-item-buy select {
    width: 100%;
    outline: none;
    border-style: none;
    background-color: #f7f7f7;
    color: #757575;
  }
  .sub-item-buy input {
    width: 100%;
    outline: none;
    border-style: none;
    background-color: #f7f7f7;
    font-size: 18px;
  }

  .select option {
    background-color: #fff;
    font-size: 18px;
    border: 4px solid red;
  }
  .select {
    border: 1px solid #e0e0e0;
    padding: 0 1.5rem;
    border-radius: 6px;
    box-sizing: border-box;
    display: block;
    cursor: pointer;
    height: 54px;
    margin: 9px 0;
  }
  .select select {
    border: none;
    width: 100%;
    height: 100%;
    background-color: transparent;
    /* box-sizing: border-box; */
    display: block;
    outline: none;
    color: #757575;
    font-weight: 400;
    font-size: 11px;
  }
  .select input {
    border: none;
    width: 100%;
    height: 100%;
    background-color: transparent;
    /* box-sizing: border-box; */
    display: block;
    outline: none;
    color: #757575;
    font-weight: 400;
    font-size: 11px;
  }

  .search {
    background-color: #f8b301;
    border: 1px solid #f8b301;
    border-radius: 0.8rem;
    font-size: 1.5rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14rem;
    margin-top: 0;
  }

  .search img {
    margin-right: 1rem;
    width: 2rem;
  }
  .searchDetails {
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #1e2130 !important;
  }
  @media (max-width: 1672px) {
    .banner-wrapper-inner {
      height: 50vh;
      min-height: 50rem;
    }
    .banner-heading h2 {
      font-size: 68px;
    }

    .banner-heading p {
      font-size: 2.6rem;
    }
  }
  @media (max-width: 1372px) {
    .banner-wrapper-inner {
      height: 40vh;
      min-height: 40rem;
    }
    .banner-heading h2 {
      font-size: 4.5rem;
      margin-top: 4rem;
      line-height: 6rem;
    }

    .banner-heading p {
      font-size: 1.6rem;
    }

    .banner-img img {
      width: 70%;
    }
  }
  @media (max-width: 1000px) {
    .tabs-container {
      grid-template-columns: 1fr 1fr 1fr;
      grid-area: "buytab selltab ." "showitem showitem showitem";
    }
    .item {
      grid-template-columns: 1fr 1fr 1fr 1fr;

      grid-gap: 2rem;
    }

    .banner-heading h2 {
      font-size: 4rem;
      /* margin-top: 5rem; */
    }

    .banner-heading p {
      font-size: 1.3rem;
    }

    .banner-img img {
      width: 80%;
    }
    #text-display {
      margin-top: -25px;
    }
  }

  @media (max-width: 768px) {
    .banner-text-slider-btn {
      justify-content: center;
      margin-top: 2rem;
    }
    #text-display {
      margin-top: -30px;
    }
    .tabs-container {
      grid-template-columns: 1fr 1fr;
      grid-area: "buytab selltab" "showitem showitem";
      bottom: 45%;
      left: 50%;
      transform: translate(-50%, -70%);
    }
    .item {
      border-top-right-radius: 0;
    }

    .tabs {
      width: 100%;
      gap: 1rem;
    }

    .tabs .tab {
      margin-right: 0;
      font-size: 1.3rem;
    }

    .item {
      grid-template-columns: 1fr;
      grid-gap: 2rem;
      width: 100%;
    }
    .banner-wrapper-inner {
      height: 85vh;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 0.5fr;
    }
    .banner-heading {
      text-align: center;
    }
    .banner-img {
      justify-content: center;
    }
    .banner-img img {
      position: absolute;
      width: 100%;
      right: 0;
    }

    .banner-heading h2 {
      font-size: 3.2 rem;
      line-height: 4rem;
      /* margin-top: 5rem; */
    }

    .banner-heading p {
      font-size: 1.2rem;
    }

    .item {
      grid-template-columns: 1fr;
      grid-gap: 1.5rem;
      padding: 1.5rem;
    }
    .sub-item-buy {
      min-width: 13rem;
      height: 5rem;
    }

    .search {
      width: 100%;
    }
    .tabs-container {
      top: 31rem;
    }
  }
  @media (max-width: 600px) {
    .banner-heading h2 {
      font-size: 2.8rem;
      line-height: 3.6rem;
      margin-top: 5.4rem;
    }
  }
  @media (max-width: 414px) {
    .banner-wrapper-inner {
      height: 95vh;
    }
  }
  @media (max-width: 390px) {
    .banner-wrapper-inner {
      height: 85vh;
    }
  }
  @media (max-width: 375px) {
    .banner-wrapper-inner {
      height: 105vh;
    }
  }
`;

const Hero = ({ parameters }) => {
  const [values, setValues] = useState({
    payload: "",
    cargo: "",
    make: "",
  });
  //console.log(values);
  const [active, setActive] = useState("active");

  // const isChecked = useSelector((state) => state.isChecked);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { nav } = useSelector((state) => state);
  const { rtoStates } = nav;
  // const isSell = useSelector((state) => state.isSell);
  const { allMakes, allYears, makeId, yearId } = useSelector(
    (state) => state.sell
  );
  const [makes, setMakes] = useState(null);
  const [years, setYears] = useState(null);
  const [currentMake, setCurrentMake] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [selectedMakeId, setSelectedMakeId] = useState("");
  const [selectedYearId, setSelectedYearId] = useState("");
  const [rtos, setRtos] = useState();
  const [selectedRtoId, setSelectedRtoId] = useState("");
  const [currentRto, setCurrentRto] = useState("");
  const [isChecked, setisChecked] = useState(true);

  useEffect(() => {
    // //console.log(rtoStates,"rto","pop")
    if (Array.isArray(rtoStates)) {
      // //console.log(rtoStates, "rto", "rto1");
      setRtos(
        rtoStates?.map((state) => (
          <MenuItem key={state.id} value={state.id}>
            {state.rto_name + " - " + state.state}
          </MenuItem>
        ))
      );
    }
    if (rtoStates?.length === 0) {
      setRtos(
        <MenuItem
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
          }}
        >
          <CircularProgress
            color="inherit"
            style={{ color: "#1E2130" }}
            size={25}
          />
        </MenuItem>
      );
    }
  }, [nav]);
  const setRtoData = (event) => {
    const selectedId = event.target.value;
    setSelectedRtoId(selectedId);
    dispatch(setRtoId({ id: selectedId }));
    const selectedRto = rtoStates.find((state) => state.id == selectedId);
    setCurrentRto(selectedRto);
  };

  useEffect(() => {
    dispatch(GetMakes());
    dispatch(GetRtoStates());
  }, []);

  useEffect(() => {
    if (Array.isArray(allMakes)) {
      setMakes(
        allMakes?.map((make) => (
          <MenuItem key={make.id} value={make.id}>
            {make.name}
          </MenuItem>
        ))
      );
    }
    if (allMakes.length === 0) {
      setMakes(
        <MenuItem
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
          }}
        >
          <CircularProgress
            color="inherit"
            style={{ color: "#1E2130" }}
            size={25}
          />
        </MenuItem>
      );
    }
  }, [allMakes]);

  const setMakeData = (event) => {
    const selectedId = event.target.value;
    setSelectedMakeId(selectedId);
    const selectedMake = allMakes.find((make) => make.id == selectedId);
    // //console.log(selectedId, selectedMake, allMakes);
    setCurrentMake(selectedMake);
  };

  // useEffect(() => {
  //   dispatch(setMakeId(currentMake));
  //   dispatch(GetYearByMake());
  // }, [ currentMake]);

  const setYearData = (event) => {
    const selectedId = event.target.value;
    setSelectedYearId(selectedId);
    const selectedYear = allYears.find((year) => year.id == selectedId);
    // //console.log(selectedId, selectedYear, allYears);
    setCurrentYear(selectedYear);
  };
  // //console.log(currentYear,currentMake,"first")

  useEffect(() => {
    dispatch(setYearId(currentYear));
    dispatch(GetModelByMakeAndYear());
    dispatch(GetVariantByMakeAndYear());
    // dispatch(GetVariantByModelFuelTransmission());
  }, [currentYear]);

  useEffect(() => {
    if (Array.isArray(allYears)) {
      setYears(
        allYears?.map((year) => (
          <MenuItem key={year.id} value={year.id}>
            {year.year}
          </MenuItem>
        ))
      );
    }
    if (allYears?.length === 0) {
      try {
        setYears(
          <MenuItem
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: "#fff",
            }}
          >
            <CircularProgress
              color="inherit"
              style={{ color: "#1E2130" }}
              size={25}
            />
            {!makeId && <p style={{ color: "#1E2130" }}>Select MakeId First</p>}
          </MenuItem>
        );
      } catch (error) { }
    }
  }, [allYears]);

  useEffect(() => {
    dispatch(setMakeId(currentMake));

    dispatch(GetYearByMake());
  }, [currentMake]);

  localStorage.setItem("currentMake", JSON.stringify(currentMake));
  localStorage.setItem("currentYear", JSON.stringify(currentYear));

  const handleDropdown = (e) => {
    e.preventDefault();
  };

  const onClickBuyTab = () => {
    dispatch(BuyTab());
  };
  const CustomIcon = () => (
    <KeyboardArrowDownIcon style={{ paddingRight: 18 }} />
  );

  // const onClickSellTab = () => {
  //   // dispatch(SellTab());
  //   // navigate("/sell");
  // };

  const handlePayload = (e) => {
    const selectedPayload = parameters?.payloadValues?.find(
      (variant) => variant.details.id === e.target.value - "0"
    );
    setValues({ ...values, payload: selectedPayload?.details });
  };
  const handleCargo = (e) => {
    const selectedCargo = parameters?.cargoLengthValues?.find(
      (variant) => variant.details.id === e.target.value - "0"
    );
    setValues({ ...values, cargo: selectedCargo?.details });
  };
  const handleMake = (e) => {
    setValues({ ...values, make: e.target.value });
  };
  const handleSearch = () => {
    // if (values.payload === "" && values.cargo === "" && values.make === "") {
    //   toast.error("Select Some fields");
    //   return;
    // }
    const payload =
      values.payload !== ""
        ? {
          payload_min: values.payload.min_payload.split(" ")[0],
          payload_max: values.payload.max_payload.split(" ")[0],
        }
        : null;
    const cargo =
      values.cargo !== ""
        ? {
          cargo_length_min: values.cargo.min_cargo_length.split(" ")[0],
          cargo_length_max: values.cargo.max_cargo_length.split(" ")[0],
        }
        : null;
    const make =
      values.make !== ""
        ? {
          makename: values.make,
        }
        : null;
    const searchParams = { ...payload, ...cargo, ...make };
    // //console.log(searchParams,"search")
    // searchParams.set("makename", e.target.value);

    navigate({
      pathname: "/second-hand-truck-for-sale",
      search: `?${createSearchParams(searchParams)}`,
    });
  };

  useEffect(() => {
    const btn1 = document.getElementById("banner-text-1");
    const btn2 = document.getElementById("banner-text-2");
    const hr1 = document.getElementById("hr1");
    const hr2 = document.getElementById("hr2");

    const text1 = document.getElementById("text1");
    const text2 = document.getElementById("text2");

    btn1.addEventListener("click", () => {
      hr2.classList.remove("active");
      hr1.classList.add("active");
      text2.classList.remove("activeContent");
      text1.classList.add("activeContent");
    });

    btn2.addEventListener("click", () => {
      hr1.classList.remove("active");
      hr2.classList.add("active");
      text1.classList.remove("activeContent");
      text2.classList.add("activeContent");
    });
  }, []);
  // console.log(!parameters, parameters, "parameters");

  return (
    <Wrapper>
      <div className="banner-wrapper-inner">
        <div className="banner-heading">
          <div className="banner-text-slider-btn">
            <span id="banner-text-1" className="text-slider-btn">
              <hr id="hr1" className="text-slider-hr active" />
            </span>

            <span id="banner-text-2" className="text-slider-btn">
              <hr id="hr2" className="text-slider-hr " />
            </span>
          </div>
          <div id="text-display">
            <Banner></Banner>
          </div>
        </div>

        <div className="banner-img">
          <img src={yellowTruck} alt="truck " />
        </div>
      </div>
      <div className="tabs-container">
        <input
          className="tabs-input"
          id="buyTab"
          type="radio"
          // onChange={handleCheckboxChange}
          name="tabControl"
          onClick={() => {
            //console.log("hello");
            setisChecked(true);
          }}
          checked={isChecked}
        />
        <input
          className="tabs-input"
          id="sellTab"
          type="radio"
          onClick={() => {
            //console.log("hello");
            setisChecked(false);
          }}
          name="tabControl"
          checked={!isChecked}
        />
        <div className="tabs">
          <label className="tab tab_buy" htmlFor="buyTab">
            <img src={isChecked ? CartIcon : CartLightIcon} alt="cart icon" />
            Buy Truck
          </label>
          <Link to="/sell-vehicle" className="tab tab_sell" htmlFor="sellTab">
            <img src={SellIcon} alt="sell icon" /> Sell Truck
          </Link>
        </div>

        <div className="item item_buy">
          <FormControl
            variant="outlined"
            style={{ borderColor: "transparent", outline: "none" }}
          >
            {/* <h3>Cargo</h3>variant="filled"
            <p>{values.cargo}</p>
            <div class="dropdown-custom"></div> */}
            <InputLabel id="payload">{"Select Payload"}</InputLabel>
            <Select
              labelId="payload"
              style={{
                backgroundColor: "#F7F7F7",
                borderRadius: 8,
              }}
              IconComponent={CustomIcon}
              onChange={handlePayload}
            >
              {parameters?.payloadValues?.map((payload) => {
                // //console.log(payload);
                return (
                  <MenuItem
                    key={payload?.details.id}
                    value={payload?.details.id}
                  >
                    {payload?.details.min_payload} -{" "}
                    {payload?.details.max_payload}
                  </MenuItem>
                );
              })}
              {!parameters.payloadValues && (
                <MenuItem
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#fff",
                  }}
                >
                  <CircularProgress
                    color="inherit"
                    style={{ color: "#1E2130" }}
                    size={25}
                  />
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            style={{ borderColor: "transparent", outline: "none" }}
          >
            <InputLabel id="cargo">Select Cargo Type</InputLabel>
            {/* <h3>Cargo</h3>
            <p>{values.cargo}</p>
            <div class="dropdown-custom"></div> */}
            <Select
              labelId="cargo"
              style={{
                backgroundColor: "#F7F7F7",
                borderRadius: 8,
              }}
              IconComponent={CustomIcon}
              onChange={handleCargo}
            >
              {parameters?.cargoLengthValues?.map((cargo) => {
                // //console.log(cargo);
                return (
                  <MenuItem key={cargo?.details.id} value={cargo?.details.id}>
                    {cargo?.details.min_cargo_length} -{" "}
                    {cargo?.details.max_cargo_length}
                  </MenuItem>
                );
              })}
              {!parameters.cargoLengthValues && (
                <MenuItem
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#fff",
                  }}
                >
                  <CircularProgress
                    color="inherit"
                    style={{ color: "#1E2130" }}
                    size={25}
                  />
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            style={{ borderColor: "transparent", outline: "none" }}
          >
            <InputLabel id="make">Select Make</InputLabel>
            <Select
              labelId="make"
              style={{
                backgroundColor: "#F7F7F7",
                borderRadius: 8,
              }}
              IconComponent={CustomIcon}
              onChange={handleMake}
            >
              {parameters?.makeValues?.map((make) => {
                // //console.log(make);
                return (
                  <MenuItem key={make?.details.id} value={make?.details.name}>
                    {make?.details.name}
                  </MenuItem>
                );
              })}
              {!parameters.makeValues && (
                <MenuItem
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#fff",
                  }}
                >
                  <CircularProgress
                    color="inherit"
                    style={{ color: "#1E2130" }}
                    size={25}
                  />
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <button
            className="sub-item-buy search"
            onClick={handleSearch}
            style={{
              cursor:
                values.payload === "" &&
                  values.cargo === "" &&
                  values.make === ""
                  ? "not-allowed"
                  : "pointer",
            }}
          >
            <img src={SearchBlackIcon} alt="search icon" />{" "}
            <b className="searchDetails">Search</b>
          </button>
        </div>
        <div className="item item_sell">
          <FormControl
            variant="outlined"
            style={{ borderColor: "transparent", outline: "none" }}
          >
            <InputLabel id="brand">Brand</InputLabel>
            <Select
              labelId="brand"
              style={{
                backgroundColor: "#F7F7F7",
                borderRadius: 8,
              }}
              IconComponent={CustomIcon}
              onChange={setMakeData}
            >
              {makes}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            style={{ borderColor: "transparent", outline: "none" }}
          >
            <InputLabel id="year">Year</InputLabel>
            <Select
              labelId="year"
              style={{
                backgroundColor: "#F7F7F7",
                borderRadius: 8,
              }}
              IconComponent={CustomIcon}
              value={selectedYearId}
              onChange={setYearData}
            // disabled={!makeId}
            >
              {years}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            style={{ borderColor: "transparent", outline: "none" }}
          >
            <InputLabel id="state">Registered State</InputLabel>
            <Select
              labelId="state"
              style={{
                backgroundColor: "#F7F7F7",
                borderRadius: 8,
              }}
              IconComponent={CustomIcon}
              value={selectedRtoId}
              onChange={setRtoData}
            >
              {rtos}
            </Select>
          </FormControl>
          <button
            className="sub-item-buy search"
            onClick={() => {
              if (yearId && yearId !== "undefined") navigate("/sell1");
            }}
            disabled={!yearId}
          >
            <p>Next</p>
            <img
              src={experimentArrowNext}
              alt="search icon"
              style={{ height: "15px", paddingLeft: "1rem" }}
            />
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default Hero;
