import { createSlice } from "@reduxjs/toolkit";

export const exploreSlice = createSlice({
  name: "explore",
  initialState: {
    // filter:[],
    rto_state: [],
    payload_min: 0,
    payload_max: 30,
    cargo_length_min: 0,
    cargo_length_max: 100,
    makename: [],
    yearvalues: [],
    fuelvalues: [],
    weight_min: "",
    weight_max: "",
    body_type: [],
    km_driven_min: "",
    km_driven_max: "",
    price_min: 0,
    price_max: 100,
    ownervalues: [],
    modelname: [],

    // sortBy: {
    //   category: "Price :",
    //   sort: "Low to High",
    // },
  },
  reducers: {
    getFilterCategory: (state, action) => {
      const newId = `${action.payload.id}${action.payload.label}`;
      const newObj = { id: newId, label: action.payload.label };

      const existed = state.filter.find((currItem) => currItem.id === newId);
      let newArr;
      if (existed !== undefined) {
        newArr = state.filter.filter((item) => item.id !== existed.id);
        //"existed", newArr);
      } else {
        newArr = [...state.filter, newObj];
        //"new", newArr);
      }

      return {
        ...state,
        filter: [],
      };
    },
    clearAll: (state, action) => {
      // //"clear all");

      return {
        ...state,
        rto_state: [],
        payload_min: 0,
        payload_max: 30,
        cargo_length_min: 0,
        cargo_length_max: 100,
        makename: [],
        yearvalues: [],
        fuelvalues: [],
        weight_min: "",
        weight_max: "",
        body_type: [],
        km_driven_min: "",
        km_driven_max: "",
        price_min: 0,
        price_max: 100,
        ownervalues: [],
        modelname: [],
        brandname: [],
      };
    },
    closeFilter: (state, action) => {
      //"close filter");
      // const newArra = state.filter.filter(
      //   (item) => item.id !== action.payload.id
      // );
      // return {
      //   ...state,
      //   filter: newArra,
      // };
    },

    rangeChange: (state, action) => {
      return {
        ...state,
        price_min: action.payload.min,
        price_max: action.payload.max,
      };
    },
    payloadRangeChange: (state, action) => {
      return {
        ...state,
        payload_min: action.payload.min,
        payload_max: action.payload.max,
      };
    },
    cargoRangeChange: (state, action) => {
      return {
        ...state,
        cargo_length_min: action.payload.min,
        cargo_length_max: action.payload.max,
      };
    },
    makeAdded: (state, action) => {
      return {
        ...state,
        makename: [...state.makename, action.payload.name],
      };
    },
    makeRemove: (state, action) => {
      return {
        ...state,
        makename: state.makename.filter((name) => name !== action.payload.name),
      };
    },
    makeAllRemove: (state, action) => {
      return {
        ...state,
        makename: [],
      };
    },
    ownerAdded: (state, action) => {
      return {
        ...state,
        ownervalues: [...state.ownervalues, action.payload],
      };
    },
    ownerRemove: (state, action) => {
      return {
        ...state,
        ownervalues: state.ownervalues.filter(
          (owner) => owner !== action.payload
        ),
      };
    },
    ownerAllRemove: (state, action) => {
      return {
        ...state,
        ownervalues: [],
      };
    },
    modelAdded: (state, action) => {
      return {
        ...state,
        modelname: [...state.modelname, action.payload],
      };
    },
    brandAdded: (state, action) => {
      return {
        ...state,
        brandname: [...state.brandname, action.payload],
      };
    },
    modelRemove: (state, action) => {
      return {
        ...state,
        modelname: state.modelname.filter((model) => model !== action.payload),
      };
    },
    modelAllRemove: (state, action) => {
      return {
        ...state,
        modelname: [],
      };
    },
    rtoAdded: (state, action) => {
      return {
        ...state,
        rto_state: [...state.rto_state, action.payload],
      };
    },
    rtoRemove: (state, action) => {
      return {
        ...state,
        rto_state: state.rto_state.filter((rto) => rto !== action.payload),
      };
    },
    rtoAllRemove: (state, action) => {
      return {
        ...state,
        rto_state: [],
      };
    },
    yearAdded: (state, action) => {
      return {
        ...state,
        yearvalues: [...state.yearvalues, action.payload],
      };
    },
    yearRemove: (state, action) => {
      return {
        ...state,
        yearvalues: state.yearvalues.filter((year) => year !== action.payload),
      };
    },
    yearAllRemove: (state, action) => {
      return {
        ...state,
        yearvalues: [],
      };
    },
    fuelAdded: (state, action) => {
      return {
        ...state,
        fuelvalues: [...state.fuelvalues, action.payload],
      };
    },
    fuelRemove: (state, action) => {
      return {
        ...state,
        fuelvalues: state.fuelvalues.filter((fuel) => fuel !== action.payload),
      };
    },
    fuelAllRemove: (state, action) => {
      return {
        ...state,
        fuelvalues: [],
      };
    },
    kmAdded: (state, action) => {
      return {
        ...state,
        km_driven_min: action.payload.min,
        km_driven_max: action.payload.max,
      };
    },
    kmRemove: (state, action) => {
      return {
        ...state,
        km_driven_min: "",
        km_driven_max: "",
      };
    },
    selectSortBy: (state, action) => {
      //"select sort by");
      // const load = {
      //   category: action.payload.category,
      //   sort: action.payload.sort,
      // };
      // return {
      //   ...state,
      //   sortBy: load,
      // };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getFilterCategory,
  clearAll,
  closeFilter,
  rangeChange,
  payloadRangeChange,
  selectSortBy,
  makeAdded,
  makeRemove,
  makeAllRemove,
  ownerAdded,
  ownerRemove,
  ownerAllRemove,
  modelAdded,
  modelRemove,
  modelAllRemove,
  cargoRangeChange,
  rtoAdded,
  rtoRemove,
  rtoAllRemove,
  yearAdded,
  yearRemove,
  yearAllRemove,
  fuelAdded,
  fuelRemove,
  fuelAllRemove,
  kmAdded,
  kmRemove,
  brandAdded,
} = exploreSlice.actions;

export default exploreSlice.reducer;
