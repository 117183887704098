import React from "react";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import "./TermsAndCondition.css";

const TermsAndConditionComponent = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Terms and Conditions - Cavalo" },
  ];

  return (
    <div className="wrapper">
      <BreadCrumbs breadcrumbs={breadcrumbs} />

      <h2 className="terms-condition-heading-style">
        Terms And Conditions - Cavalo
      </h2>

      <p className="term-condition-info-style">
        Welcome to Cavalo, your trusted online platform for buying and selling
        refurbished trucks. The following terms and conditions govern your use
        of our website and the services provided by Cavalo. By accessing or
        using our platform, you agree to comply with and be bound by these
        terms. If you do not agree with any part of these terms, please refrain
        from using our services.
      </p>

      <div className="terms-of-use-style">Terms of Use</div>

      <div className="terms-of-use-content">
        <h3>1. Acceptance of Terms</h3>
        <p>
          By using Cavalo, you acknowledge that you have read, understood, and
          agree to be bound by these terms and conditions. These terms apply to
          all users of the site, including buyers, sellers, and browsers.
        </p>
      </div>

      <div className="terms-of-use-content">
        <h3>2. Services Provided</h3>
        <p>
          Cavalo offers an online marketplace for the purchase and sale of
          refurbished trucks. We connect buyers with reliable sellers, providing
          a platform to showcase and discover a diverse range of refurbished
          trucks. Our services include but are not limited to browsing listings,
          posting trucks for sale, and facilitating transactions.
        </p>
      </div>
      <div className="terms-of-use-content">
        <h3>3. User Accounts</h3>
        <p>
          To access certain features of Cavalo, you may be required to create a
          user account. You are responsible for maintaining the confidentiality
          of your account information, including your username and password. Any
          activity that occurs under your account is your sole responsibility.
          If you suspect unauthorized use of your account, please notify us
          immediately.
        </p>
      </div>
      <div className="terms-of-use-content">
        <h3>4. Listing and Selling</h3>
        <p>
          If you choose to list a truck for sale on Cavalo, you agree to provide
          accurate and up-to-date information about the truck. You are solely
          responsible for the content of your listings and for ensuring that
          your trucks comply with applicable laws and regulations.
        </p>
      </div>
      <div className="terms-of-use-content">
        <h3>5. Buying</h3>
        <p>
          As a buyer on Cavalo, you agree to carefully review listings, ask any
          necessary questions, and make informed purchasing decisions. All
          transactions are solely between buyers and sellers, and Cavalo is not
          responsible for the condition, legality, or quality of the trucks
          listed.
        </p>
      </div>
      <div className="terms-of-use-content">
        <h3>6. Prohibited Activities</h3>
        <p>
          You agree not to engage in any activities that violate these terms or
          applicable laws. Prohibited activities include but are not limited to
          fraud, harassment, misrepresentation, and infringement of intellectual
          property rights.
        </p>
      </div>
      <div className="terms-of-use-content">
        <h3>7. Privacy Policy</h3>
        <p>
          Your use of Cavalo is also governed by our Privacy Policy, which
          outlines how we collect, use, and protect your personal information.
          Please review our Privacy Policy to understand our practices.
        </p>
      </div>
      <div className="terms-of-use-content">
        <h3>8. Modifications to Terms</h3>
        <p>
          Cavalo reserves the right to update or modify these terms and
          conditions at any time without prior notice. Continued use of the
          platform after such modifications constitutes acceptance of the
          updated terms.
        </p>
      </div>
      <div className="terms-of-use-content">
        <h3>9. Termination of Services</h3>
        <p>
          Cavalo reserves the right to terminate or suspend access to our
          services for any user at our discretion, without prior notice or
          explanation.
        </p>
      </div>
      <div className="terms-of-use-content">
        <h3>10. Governing Law</h3>
        <p>
          These terms and conditions are governed by and construed following the
          laws. Any disputes arising from or in connection with these terms
          shall be subject to the exclusive jurisdiction of the courts.
        </p>
      </div>

      <p className="terms-condition-footer-info-style">
        By using Cavalo, you agree to abide by these terms and conditions. If
        you have any questions or concerns, please contact us at info@cavalo.in.
      </p>
    </div>
  );
};

export default TermsAndConditionComponent;
