import React, { useEffect, useRef, useState } from "react";
import qs from "qs";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { root_url } from "./utils/url.constant";
import { IoMdClose } from "react-icons/io";
import Header from "./components/Header1";
import './Home.css'
import { LuUser } from "react-icons/lu";
import { SlScreenSmartphone } from "react-icons/sl";
import rightArrow from "./assets/rightArrow.svg";
import { useNavigate } from "react-router-dom";
import { ExploreTruckItemLanding } from "./components";
import { get } from "./utils/functions";
import styled from "styled-components";
import Select from 'react-select';
import home_client from './assets/home_client.webp'
import Wrapper1 from "./wrappers/HomeCommon";
import { FaRegCirclePlay } from "react-icons/fa6";
import Footer from "./components/Footer1";
import ExploreTruckHome from "./components/ExploreTruckHome";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import happy1 from './assets/happy1.webp';
import happy2 from './assets/happy2.webp';
import happy3 from './assets/happy3.webp';
import happy4 from './assets/happy4.webp';
import happy5 from './assets/happy5.webp';
import happy6 from './assets/happy6.webp';
import happy7 from './assets/happy7.webp';
import happy8 from './assets/happy8.webp';
import happy9 from './assets/happy9.webp';
import happy10 from './assets/happy10.webp';
import happy11 from './assets/happy11.webp';
import happy12 from './assets/happy12.webp';
import happy13 from './assets/happy13.webp';
import happy14 from './assets/happy14.webp';
import happy15 from './assets/happy15.webp';
import happy16 from './assets/happy16.webp';
import CavaloTruck from './assets/bgyellowTruck.png'

const option = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const categoryList = [
  { value: "Truck", label: "Truck" },
  { value: "Pickup", label: "Pickup" },
  { value: "Mini Truck", label: "Mini Truck" },
  { value: "Tippers", label: "Tippers" },
  { value: "Trailer", label: "Trailer" },
  { value: "3 Wheelers", label: "3 Wheelers" },
  { value: "Auto Rickshaw", label: "Auto Rickshaw" },
  { value: "Tempo Traveller", label: "Tempo Traveller" },
  { value: "Transit Mixer", label: "Transit Mixer" },
  { value: "E Rickshaw", label: "E Rickshaw" },
];


export default function Home() {

  const [stateArr, setStateArr] = useState([]);
  const [closeBtn, setCloseBtn] = useState(false);
  const [number, setNumber] = useState("");

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [year, setYear] = useState("");
  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);


  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);

  const navigate = useNavigate();
  const [vehicles, setVehicles] = useState([]);
  const [height, setheight] = useState();
  const [category, setcategory] = useState();



  useEffect(() => {

    axios
      .get(`${root_url}/location/states`)
      .then((res) => {
        setStateList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });


  }, []);

  useEffect(() => {
    if (category && category?.value) {
      axios
        .get(`${root_url}/vehicle/brands?type=${category?.value}`)
        .then((res) => {
          setMakeList(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

  }, [category]);

  useEffect(() => {
    if (make && make?.value) {
      const url = `${root_url}/vehicle/models/variants/brands/${make?.value}?type=${category?.value}`;
      axios
        .get(url)
        .then((res) => {
          // Filter out duplicate model names
          setModelList(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [make]);

  // useEffect(() => {
  //     if (state && state?.state_id) {
  //       axios
  //         .get(
  //           `${root_url}/location/districts/state/${state.state_id}`
  //         )
  //         .then((res) => {
  //           setDistrictList(res.data.data);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     }
  // }, [state]);

  const appendSpreadsheet = async () => {
    if (name === "" || number === "" || state === "" || make === "" || category == "" || model == "" || year == "") {
      toast.error("Fields cannot be empty");
      return;
    }

    try {
      let dataObj = qs.stringify({
        name,
        phone: number,
        state: state.value,
        vehicle: category.value,
        make: make.label,
        model: model.label,
        message: "$lead",
        action: "Landing Page Lead",
      });

      console.log(dataObj, "dataObjdataObjdataObj");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${root_url}/buy/contact`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: dataObj,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data?.status_code === 400) {
            toast.error(response?.data?.message);
          } else {
            toast.success("We Will Contact You Soon.");
            setName("")
            setState("")
            setModel("")
            setMake("")
            setcategory("")
            setNumber("")
            setYear("")
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const styling = {
    control: (base) => ({
      ...base,
      boxShadow: "none !important",
      padding: "11px 18px !important",
      borderRadius: "8px !important",
      border: "1px solid rgba(226, 226, 226, 1) !important",
      gap: "10px !important",
      width: "100% !important",
      minHeight: "unset !important",
      marginBottom: '15px',
      "@media screen and (min-width:300px) and (max-width:600px)": {
        ...base["@media screen and (min-width:300px) and (max-width:600px)"],
        padding: '5px 12px !important',
        marginBottom: '10px',
      }
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#000 !important",
      "&hover": {
        color: "#000 !important",
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none !important",
    }),

    placeholder: (provided) => ({
      ...provided,
      fontSize: "15px !important",
      fontWeight: "500 !important",
      fontFamily: "Mulish !important",
      color: "#888888 !important",
      margin: "0px !important",
      "@media screen and (min-width:300px) and (max-width:600px)": {
        ...provided["@media screen and (min-width:300px) and (max-width:600px)"],
        fontSize: "14px !important",
      }
    }),

    valueContainer: (provided) => ({
      ...provided,
      padding: "0px !important",
      fontSize: "15px !important",
      fontWeight: "500 !important",
      color: "#888888 !important",
      "@media screen and (min-width:300px) and (max-width:600px)": {
        ...provided["@media screen and (min-width:300px) and (max-width:600px)"],
        fontSize: "14px !important",
      }
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      color: "#09021C !important",
      fontFamily: "Mulish !important",
      fontSize: "15px !important",
      fontWeight: "500 !important",
      background: isFocused
        ? "rgba(237, 237, 237, 1) !important"
        : isSelected
          ? "rgba(237, 237, 237, 1) !important"
          : "white",
      cursor: "pointer !important",
      "@media screen and (min-width:300px) and (max-width:600px)": {
        ...base["@media screen and (min-width:300px) and (max-width:600px)"],
        fontSize: "14px !important",
      }
    }),
  };

  const handleGet = async () => {
    console.log("tertertretioetuertioretrotuertitroit")
    try {
      const resp = await get("/sell/new/random/vehicles", {}, {});
      if (resp.status_code === 200) setVehicles(resp.data);
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    handleGet()
  }, []);

  console.log(vehicles, "vehiclesvehicles")

  const breakpoints = {
    300: {
      slidesPerView: 1.1,
    },
    350: {
      slidesPerView: 1.2,
    },
    400: {
      slidesPerView: 1.4,
    },
    450: {
      slidesPerView: 1.6,
    },
    500: {
      slidesPerView: 1.8,
    },
    550: {
      slidesPerView: 2,
    },
    650: {
      slidesPerView: 2.2,
    },
    700: {
      slidesPerView: 2.5,
    },
    768: {
      slidesPerView: 2.8,
    },
    1000: {
      slidesPerView: 3.6,
    },
    1100: {
      slidesPerView: 4,
    },
    1200: {
      slidesPerView: 4.3,
    },
    1300: {
      slidesPerView: 4.5,
    },
    1400: {
      slidesPerView: 5,
    },

    1500: {
      slidesPerView: 5.3,
    },

    1600: {
      slidesPerView: 5.5,
    },

    1800: {
      slidesPerView: 6,
    },

  }


  return (
    <>
      <Header />

      <div className="banner_section" id="banner">
        <div className="container">
          <div className="row">
            <div className="first_box">
              <h1 className="heading">
                Save Up to 50% on Quality Used Trucks /{" "}
                <span>कम दाम में ट्रक खरीदें</span>
              </h1>
            </div>
            <div className="second_box">
              <div className="form">
                <h2 className="form_head">
                  Query Form / <span>प्रश्न प्रपत्र</span>
                </h2>
                <p className="para">

                </p>

                <div className="input_group">
                  <LuUser />
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="input_row">
                  <div className="input_group1">
                    <SlScreenSmartphone />
                    <input
                      type="text"
                      className="input"
                      placeholder="Enter your phone number"
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                    />
                  </div>
                  <div className="select_box">
                    <Select
                      value={state}
                      options={stateList.map((combined) => ({
                        label: combined.state_name,
                        value: combined.state_name,
                      }))}
                      onChange={(val) => setState(val)}
                      placeholder="Select State "
                      styles={styling}
                    />
                  </div>
                </div>

                <div className="input_row">
                  <div className="select_box">
                    <Select
                      value={category}
                      options={categoryList}
                      onChange={(val) => setcategory(val)}
                      placeholder="Select Category "
                      styles={styling}
                    />
                  </div>
                  <div className="select_box">
                    <Select
                      value={make}
                      options={makeList.map((combined) => ({
                        label: combined.brand_name,
                        value: combined.brand_id,
                      }))}
                      onChange={(val) => setMake(val)}
                      placeholder="Select Model"
                      styles={styling}
                    />
                  </div>
                </div>

                <div className="input_row">
                  <div className="select_box">
                    <Select
                      options={
                        Object.values(modelList)?.length > 0
                          ? Object.values(modelList)
                            .flatMap((item) =>
                              item.variants.map((variant) => ({
                                model_id: item.model.model_id,
                                model_name: item.model.model_name,
                                variant_id: variant.variant_id,
                                variant_name: variant.variant_name,
                              }))
                            )
                            .map((combined) => ({
                              label:
                                combined.model_name +
                                " - " +
                                combined.variant_name,
                              value: combined.variant_id,
                            }))
                          : []
                      }
                      value={model}
                      onChange={(val) => {
                        setModel(val);
                      }}
                      placeholder="Select Model"
                      styles={styling}
                    />
                  </div>

                  <div className="select_box">
                    <Select
                      value={year}
                      options={[...Array(25)].map((_, ind) => ({
                        label: 2024 - ind,
                        value: 2024 - ind,
                      }))}
                      onChange={(val) => setYear(val)}
                      placeholder="Select Year"
                      styles={styling}
                    />
                  </div>
                </div>

                <button
                  className="sub_btn"
                  type="button"
                  onClick={() => appendSpreadsheet()}
                >
                  Submit/ <span>जमा करना</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Wrapper1>
        <div className="explore-truck-sections3">
          <div className="container1">
            <img src={CavaloTruck} alt="img" className="aboutus-img" />

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="explore-truck-heading">
                <h3>
                  About Us /
                  <span>
                    {" "}
                    हमारे बारे में
                  </span>
                </h3>
              </div>

              <div className="aboutUsDescription">
                Welcome to Cavalo, the online platform for buying and selling
                refurbished trucks. We are a team of passionate truck enthusiasts
                who are committed to making the process of buying and selling
                trucks as seamless as possible. With Cavalo, you can easily find
                the perfect refurbished truck for your business or sell your own
                truck at a fair price. Our platform is designed to be
                user-friendly and intuitive, with a wide range of filters and
                search options to help you find the perfect truck. Whether you're
                a seasoned truck owner or new to the market, Cavalo is the perfect
                place to buy or sell your refurbished truck. So why wait? Start
                your journey with Cavalo today!
              </div>
            </div>
          </div>
        </div>
      </Wrapper1>




      <Wrapper1>
        <div className="explore-truck-sections">
          <div className="container">
            <div className="explore-truck-heading">
              <h3>
                Explore Our Wide Selection of Used Trucks /
                <span>
                  {" "}
                  हमारे उपयोग किए गए ट्रकों के विस्तृत चयन को देखें
                </span>
              </h3>
            </div>

            <div className="explore-truck-items">

              <ExploreTruckHome />

            </div>
          </div>
        </div>
      </Wrapper1>

      <Wrapper1>
        <div className="explore-truck-sections">
          <div className="container">
            <div className="explore-truck-heading">
              <h3>
                3000+ Happy Customers Served By Cavalo /
                <span>
                  {" "}
                  Cavalo द्वारा 3000+ खुशहाल ग्राहकों को सेवा प्रदान की गई
                </span>
              </h3>
            </div>

            <div className="explore-truck-items2">
              <Swiper
                slidesPerView={5}
                spaceBetween={20}
                navigation={true}
                modules={[Navigation]}
                breakpoints={breakpoints}
                className="mySwiper2"
              >
                <SwiperSlide>
                  <img
                    crossOrigin="anonymous"
                    src={happy1}
                    alt="explore truck "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    crossOrigin="anonymous"
                    src={happy2}
                    alt="explore truck "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    crossOrigin="anonymous"
                    src={happy3}
                    alt="explore truck "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    crossOrigin="anonymous"
                    src={happy4}
                    alt="explore truck "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    crossOrigin="anonymous"
                    src={happy5}
                    alt="explore truck "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    crossOrigin="anonymous"
                    src={happy6}
                    alt="explore truck "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    crossOrigin="anonymous"
                    src={happy7}
                    alt="explore truck "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    crossOrigin="anonymous"
                    src={happy8}
                    alt="explore truck "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    crossOrigin="anonymous"
                    src={happy9}
                    alt="explore truck "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    crossOrigin="anonymous"
                    src={happy10}
                    alt="explore truck "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    crossOrigin="anonymous"
                    src={happy11}
                    alt="explore truck "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    crossOrigin="anonymous"
                    src={happy12}
                    alt="explore truck "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    crossOrigin="anonymous"
                    src={happy13}
                    alt="explore truck "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    crossOrigin="anonymous"
                    src={happy14}
                    alt="explore truck"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    crossOrigin="anonymous"
                    src={happy15}
                    alt="explore truck "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    crossOrigin="anonymous"
                    src={happy16}
                    alt="explore truck "
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </Wrapper1>

      <Wrapper1>
        <div className="explore-truck-sections">
          <div className="container">
            <div className="explore-truck-heading">
              <h3>
                What Our Customer Say /
                <span> हमारे ग्राहक क्या कहते हैं</span>
              </h3>
            </div>

            <div
              className="explore-truck-items"
              style={{ marginTop: "30px" }}
            >
              <div className="explore-truck-item">
                <iframe
                  width="100%"
                  height="400"
                  src="https://www.youtube.com/embed/OZy2auwfoFw?si=TOSqvRmnq8EFAxSA"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="explore-truck-item">
                <iframe
                  width="100%"
                  height="400"
                  src="https://www.youtube.com/embed/OZy2auwfoFw?si=DdJgvtnywz0byy6F"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="explore-truck-item">
                <iframe
                  width="100%"
                  height="400"
                  src="https://www.youtube.com/embed/JJGqg6Q_8eM?si=fVMttK9bVGifXNRv"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="explore-truck-item">
                <iframe
                  width="100%"
                  height="400"
                  src="https://www.youtube.com/embed/k-ZimKzbaDc?si=Vf4nF0_Akzs6I7sh"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </Wrapper1>

      <Footer />

      {closeBtn && (
        <div className="main_modal">
          <div className="modal">
            <div className="icon" onClick={() => setCloseBtn(!closeBtn)}>
              <IoMdClose />
            </div>

            <div className="fields">
              <input
                type="text"
                placeholder="Name"
                className="input_box"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Mobile No."
                className="input_box"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />

              <input
                type="email"
                placeholder="Email"
                className="input_box"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <select
                name="state"
                className="cusomtinput"
                onChange={(e) => setState(e.target.value)}
                value={state}
              >
                <option>Select State</option>
                {stateArr?.length > 0
                  ? stateArr.map((item) => (
                    <option value={item.state_name}>
                      {item.state_name}
                    </option>
                  ))
                  : null}
              </select>
              <input
                type="text"
                className="input_box"
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                placeholder="District"
              />
            </div>
            <button
              className="btn_submit"
              type="button"
              onClick={appendSpreadsheet}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
}
