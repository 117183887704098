import { createContext, useState } from "react";

export const EmployeeContext = createContext();

export default function EmployeeProvider({ children }) {
  //JSON.parse(localStorage.getItem("data")));
  const [employeeData, setEmployeeData] = useState({});
  const [islogined, setIslogined] = useState(false);
  const [active, setActive] = useState("user");
  const [currentPage, setCurrentPage] = useState(1);
  const [fieldsNeeded, setfieldsNeeded] = useState({});
  const [linkNeeded, setlinkNeeded] = useState("");
  const [idSelected, setidSelected] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [template, setTemplate] = useState("");

  const value = {
    template,
    setTemplate,
    employeeData,
    setEmployeeData,
    islogined,
    setIslogined,
    active,
    setActive,
    currentPage,
    setCurrentPage,
    fieldsNeeded,
    setfieldsNeeded,
    linkNeeded,
    setlinkNeeded,
    idSelected,
    setidSelected,
    prevPage,
    setPrevPage,
  };
  return (
    <EmployeeContext.Provider value={value}>
      {children}
    </EmployeeContext.Provider>
  );
}
