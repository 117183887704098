import { ReactComponent as Location } from "../../assets/location.svg";
import { ReactComponent as Mail } from "../../assets/MailIcon.svg";
import { ReactComponent as Phone } from "../../assets/Phone.svg";
import { ReactComponent as Person } from "../../assets/person.svg";
import "./PersonalDetailSection.css";

const PersonalDetailSection = ({ data, onChnageHandler, state, district, tehsil }) => {
  return (
    <div className="personal-detail-main-box">
      <h2 >Share Essential Personal Details</h2>

      <div className="personal-detail-fields-box">
        <div className="field-icon-container name-grid-item">
          <Person className="input-field-icon" />
          <input
            placeholder={"Full Name"}
            name="fullName"
            type="text"
            onChange={onChnageHandler}
            value={data?.fullName}
          />
        </div>

        <div className="field-icon-container phone-grid-item">
          <Phone className="input-field-icon" />
          <span>+91</span>
          <input
            placeholder={"Phone Number"}
            type="text"
            name="mobileNumber"
            onChange={onChnageHandler}
            value={data?.mobileNumber}
          />
        </div>
        <div className="field-icon-container mail-grid-item">
          <Mail className="input-field-icon" />
          <input
            placeholder={"Email Id"}
            type="email"
            name="email"
            onChange={onChnageHandler}
            value={data?.email}
          />
        </div>

        <div className="field-icon-container state-grid-item">
          <Location className="input-field-icon" />

          <select name="state" onChange={onChnageHandler} value={data?.state?.state_id}>
            <option>Select State</option>
            {state?.length > 0 ? state.map(item => (
              <option value={item.state_id}>{item.state_name}</option>
            )) : null}

          </select>
        </div>
        <div className="field-icon-container district-grid-item">
          <Location className="input-field-icon" />
          <select
            placeholder={"Selec District"}
            onChange={onChnageHandler}
            value={data?.district_id}
            name="district"
          >
            <option>Select District</option>
            {district?.length > 0 ? district.map(item => (
              <option value={item.district_id}>{item.district_name}</option>
            )) : null}
          </select>
        </div>
        <div className="field-icon-container tehsil-grid-item">
          <Location className="input-field-icon" />
          <select
            placeholder={"Select Tehsil"}
            onChange={onChnageHandler}
            value={data?.tehsil_id}
            name="tehsil"
          >
            <option>Select Tehsil</option>
            {tehsil?.length > 0 ? tehsil.map(item => (
              <option value={item.tehsil_id}>{item.tehsil_name}</option>
            )) : null}
          </select>
        </div>

      </div>
    </div>
  );
};

export default PersonalDetailSection;
